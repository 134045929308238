import React, { useState } from 'react';
import {
  Container,
  Paper,
  Box,
  Avatar,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
  TextField,
  Button
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

function Profile() {
  const { register, handleSubmit, formState: { errors }, trigger } = useForm();
  const [selectedTab, setSelectedTab] = useState(0);
  const [addresses, setAddresses] = useState( []);
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    zipCode: '',
  });

  const user = useSelector((state) => state.users.user);

  const initials = user.firstName.charAt(0) + user.lastName.charAt(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };
  
  const isValidZipCode = (zipCode) => {
    // Add your zip code validation logic here
    // For example, you can use a regular expression to check if the zip code is valid
    const zipCodeRegex = /^\d{5}$/;
    return zipCodeRegex.test(zipCode);
  };

  const handleAddAddress = () => {

    
    if (Object.values(newAddress).every(field => field.trim())) {
      setAddresses([...addresses, newAddress]);
      setNewAddress({
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      });
    }
  };

  return (
    <Container disableGutters maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 5 }} gutterBottom>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar
            alt="John Doe"
            src=""
            sx={{ width: 100, height: 100, marginBottom: 2, fontSize: 50 }}
          >
            {initials}
          </Avatar>
          <Typography variant="h4" component="h1">
            {user.firstName} {user.lastName}
          </Typography>
        </Box>
        <Divider sx={{ marginY: 3 }} />
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label="Personal Information" />
          <Tab label="Orders" />
          <Tab label="Addresses" />
          <Tab label="Payment Methods" />
        </Tabs>
        <Divider sx={{ marginY: 3 }} />
        {selectedTab === 0 && (
          <Box>
            <Typography variant="h6" component="h2" gutterBottom>
              Personal Information
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Email" secondary={user.email} />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText primary="Phone" secondary={user.phone} />
              </ListItem>
              <Divider component="li" />
              {/* <ListItem>
                <ListItemText primary="Address" secondary={user.address} />
              </ListItem> */}
            </List>
          </Box>
        )}
        {selectedTab === 1 && (
          <Box>
            <Typography variant="h6" component="h2" gutterBottom>
              Orders
            </Typography>
            <Typography variant="body1">Order history will be displayed here.</Typography>
          </Box>
        )}
        {selectedTab === 2 && (
          <Box>
            <Typography variant="h6" component="h2" gutterBottom>
              Addresses
            </Typography>
            <List>
              {addresses.map((address, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`Address ${index + 1}`}
                    secondary={`${address.street}, ${address.city}, ${address.state}, ${address.zipCode}`}
                  />
                </ListItem>
              ))}
            </List>
            <Box mt={1}>
              <TextField
                label="Street"
                variant="outlined"
                fullWidth
                name="street"
                value={newAddress.street}
                onChange={handleInputChange}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                name="city"
                value={newAddress.city}
                onChange={handleInputChange}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="State"
                variant="outlined"
                fullWidth
                name="state"
                value={newAddress.state}
                onChange={handleInputChange}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Zip Code"
                variant="outlined"
                fullWidth
                name="zipCode"
                // value={newAddress.zipCode}
                onChange={handleInputChange}
                sx={{ marginBottom: 2 }}
                {...register("zipCode", { required: "zipCode is required", validate: isValidZipCode })}
                error={!!errors.zipCode}
                helperText={errors.lastName?.zipCode}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAddress}
              >
                Add Address
              </Button>
            </Box>
          </Box>
        )}
        {selectedTab === 3 && (
          <Box>
            <Typography variant="h6" component="h2" gutterBottom>
              Payment Methods
            </Typography>
            <Typography variant="body1">Saved payment methods will be displayed here.</Typography>
          </Box>
        )}
      </Paper>
    </Container>
  );
}

export default Profile;