import React, { useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    TextField,
    Button,
    Paper,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    StepIcon,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { checkoutSteps, otherBanks, populerBanks } from '../mockData';
import { Person, Person2Outlined } from '@mui/icons-material';
import { getFilteredCartItems } from './utils/commonUtil';
import Billing from './Cart/Billing';

const Checkout = () => {
    const [paymentMethod, setPaymentMethod] = useState('');
    const [selectedBank, setSelectedBank] = useState('');
    const [otherBank, setOtherBank] = useState('');
    const [activeStep, setActiveStep] = useState(0);

    const user = useSelector((state) => state.users.user);

    const cartItems = useSelector((state) => state.carts.cart);

    const filteredCartItems = getFilteredCartItems(cartItems);

    const totalAmount = filteredCartItems?.reduce((acc, item) => acc + item.discountedPrice * item.quantity, 0);

    const handlePaymentChange = (event) => {
        setPaymentMethod(event.target.value);
    };
    const handleBankChange = (event) => {
        setSelectedBank(event.target.value);
    };

    const handleOtherBankChange = (event) => {
        setOtherBank(event.target.value);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Paper elevation={0} sx={{ padding: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            {user.firstName} {user.lastName}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {user.email}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {user.phone}
                        </Typography>
                    </Paper>
                );
            case 1:
                return (
                    <Paper elevation={0} sx={{ padding: 1 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Name" fullWidth margin="normal" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Phone Number" fullWidth margin="normal" />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField label="Society/Street" fullWidth margin="normal" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="City" fullWidth margin="normal" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Zip Code" fullWidth margin="normal" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="State" fullWidth margin="normal" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Landmark" fullWidth margin="normal" />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                                <TextField label="Country" fullWidth margin="normal" />
                            </Grid> */}
                        </Grid>
                    </Paper>
                );
            case 2:
                return (
                    <Paper elevation={0} sx={{ padding: 3 }}>
                        <RadioGroup value={paymentMethod} onChange={handlePaymentChange}>
                            <FormControlLabel value="netbanking" control={<Radio color='tertiary' />} label="Net Banking" />
                            {paymentMethod === 'netbanking' && (
                                <>
                                    <Typography variant="h6" gutterBottom>
                                        Popular Banks
                                    </Typography>
                                    <RadioGroup row value={selectedBank} onChange={handleBankChange}>
                                        {populerBanks.map((bank) => (
                                            <FormControlLabel key={bank.id} value={bank.name} control={<Radio color='tertiary' />} label={<Typography lineHeight={1} variant='body2'>
                                                <img src={bank.logo} alt={bank.name} style={{ height: 50, marginRight: 10 }} />
                                            </Typography>} />
                                        ))}

                                    </RadioGroup>
                                    <FormControl margin="normal" sx={{ width: '30%' }}>
                                        <InputLabel>Other Banks</InputLabel>
                                        <Select value={otherBank} onChange={handleOtherBankChange}>
                                            {otherBanks.map((bank) => (
                                                <MenuItem key={bank.id} value={bank.name}>
                                                    {bank.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {/* <Button variant="contained" size='large' color="tertiary" sx={{ width: '30%', height: '60px' }}>
                                        <Typography variant="h6" color="white">Pay ₹{totalAmount}</Typography>
                                    </Button> */}
                                </>
                            )}
                            <FormControlLabel value="upi" control={<Radio color='tertiary' />} label="UPI" />
                            {paymentMethod === 'upi' && (
                                <TextField
                                    sx={{ width: '30%' }}
                                    label="UPI ID"
                                    name="upi"
                                    // value={paymentDetails.upi}
                                    // onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    placeholder='e.g abc@upi'
                                // error={!!errors.upi}
                                // helperText={errors.upi}
                                />
                            )}
                            <FormControlLabel value="credit" control={<Radio color='tertiary' />} label="Credit Card" />
                            {paymentMethod === 'credit' && (
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Credit Card Number"
                                            variant="outlined"
                                            name='cardNumber'
                                            fullWidth
                                            // value={cardNumber}
                                            // onChange={handleCardNumberChange}
                                            inputProps={{ maxLength: 16, pattern: "[0-9]*" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Expiry Date (MM/YY)"
                                            variant="outlined"
                                            name='expiryDate'
                                            inputProps={{ maxLength: 5, pattern: "[0-9]*" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="CVV"
                                            variant="outlined"
                                            name='cvv'
                                            inputProps={{ maxLength: 3, pattern: "[0-9]*" }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <FormControlLabel value="debit" control={<Radio color='tertiary' />} label="Debit Card" />
                            {paymentMethod === 'debit' && (
                               <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Debit Card Number"
                                            variant="outlined"
                                            name='cardNumber'
                                            fullWidth
                                            type = 'number'
                                            // value={cardNumber}
                                            // onChange={handleCardNumberChange}
                                            inputProps={{ maxLength: 16, pattern: "[0-9]*" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Expiry Date (MM/YY)"
                                            variant="outlined"
                                            name='expiryDate'
                                            inputProps={{ maxLength: 5, pattern: "[0-9]*" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="CVV"
                                            variant="outlined"
                                            name='cvv'
                                            type='number'
                                            inputProps={{ maxLength: 3, pattern: "[0-9]*" }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {/* <FormControlLabel value="cod" control={<Radio />} label="Cash on Delivery" /> */}
                        </RadioGroup>
                    </Paper>
                );
            default:
                return 'Unknown step';
        }
    }

    const getNextButtonLabel = (step) => {
        if (step === 0) {
            return 'Continue Checkout';
        } else if (step === 1) {
            return 'Deliver Here';
        } else if (step === 2) {
            return 'Pay ₹' + totalAmount;
        }
        return 'Next';
    }


    return (
        <Container>
            <Grid container spacing={4} mt={4}>
                <Grid sx={{ backgroundColor: 'azure' }} item xs={12} sm={8}>
                    <Stepper orientation='vertical' activeStep={activeStep}>
                        {checkoutSteps.map((step) => (
                            <Step key={step.id}>
                                <StepLabel sx={{backgroundColor: 'tertiary.dark', color: 'white' }}  StepIconComponent={step.icon}>
                                    <Typography color={'white'} variant='button'>{step.name}</Typography>
                                </StepLabel>
                                <StepContent sx={{ backgroundColor: 'tertiary.light' }}>
                                    <Typography>{getStepContent(activeStep)}</Typography>
                                    <div>
                                        <div>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                                                color='tertiary'
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='tertiary'
                                                sx={{ mt: 1, ml: 1, }}
                                                onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                                            >
                                                <Typography variant='button' color='white'>
                                                    {getNextButtonLabel(activeStep)}
                                                </Typography>
                                            </Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item xs={12} md={4}>
                    {/* <Paper sx={{ padding: 3, backgroundColor: 'azure', }}>
                        <Typography variant="h6">Order Summary</Typography>
                        <Divider />
                        <Typography variant="body1" mb={3}>Price: $100</Typography>
                        <Typography variant="body1" mb={3}>Total Amount: $120</Typography>
                    </Paper> */}
                    <Billing cartItems={filteredCartItems} showButton={false} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Checkout;