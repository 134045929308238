import React, { useState } from 'react';
import { TextField, Autocomplete, Box } from '@mui/material';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { categories } from '../../mockData';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';

const Search = () => {
    const [results, setResults] = useState([]);
    const navigate = useNavigate()
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const products = useSelector(state => state.products.products);
    const vendors = useSelector(state => state.vendors.vendors);
    const productCategories = useSelector(state => state.productCategories.productCategories);

    const searchWidth = isMobile ? { width: '90%' } : { width: '500px' };
    const searchInputStyles = { backgroundColor: 'white', padding: '0px 10px', height: '40px' }
    isMobile ? searchInputStyles.borderRadius = '20px' : searchInputStyles.borderRadius = '4px';


    const subcategories = categories.reduce((acc, category) => {
        return [...acc, ...category.subcategories.map(subcategory => ({ ...subcategory, categoryId: category.id }))];
    }, []);

    //find categoryid and subcategoryid for each vendor
    const getVendorCategory = () => {
        const vendorsWithCategory = vendors.map(vendor => {
            const category = categories.find(category => category.id === vendor
                .categoryId);
            const subcategory = category.subcategories.find(subcategory => subcategory.id === vendor.subcategoryId);
            return { ...vendor, categoryId: category.id, subcategoryId: subcategory.id };
        }
        );
        return vendorsWithCategory;
    }

    const onClickSearchItem = (result) => {
        const vendorsWithCategory = getVendorCategory();
        if (result.type === 'Product') {
            const product = products.find(product => product.id === result.id);
            const vendorForProduct = vendorsWithCategory.find(vendor => vendor.id === product.vendorId)
            if (!vendorForProduct) return;
            navigate(`/products/${vendorForProduct.categoryId}/${vendorForProduct.subcategoryId}/${product.vendorId}/${result.id}`);
        } else if (result.type === 'Brand') {
            const brand = vendorsWithCategory.find(brand => brand.id === result.id);
            if (!brand) return;
            navigate(`/products/${brand.categoryId}/${brand.subcategoryId}/${brand.id}`);
        } else if (result.type === 'Category') {
            const category = subcategories.find(category => category.id === result.id && category.categoryId === result.categoryId);
            if (!category) return;
            navigate(`/products/${category.categoryId}/${category
                .id}`);
        } else if (result.type === 'ProductCategory') {
            const productCategory = productCategories.find(category => category.id === result.id && category.categoryId === result.categoryId && category.subcategoryId === result.subcategoryId);
            if (!productCategory) return;
            navigate(`/products/${productCategory.categoryId}/${productCategory.subcategoryId}`);
        }
    }

    const handleSearch = (_event, value) => {
        const searchValue = value.toLowerCase();
        if (searchValue) {
            const filteredProducts = products.filter(product => product.name.toLowerCase().includes(searchValue));
            const filteredBrands = vendors.filter(brand => brand.name.toLowerCase().includes(searchValue));
            const filteredSubCategories = subcategories.filter(category => category.name.toLowerCase().includes(searchValue));
            const filteredProucCategories = productCategories.filter(category => category.name.toLowerCase().includes(searchValue));

            setResults([
                ...filteredProducts.map(product => ({ type: 'Product', name: product.name, id: product.id })),
                ...filteredBrands.map(brand => ({ type: 'Brand', name: brand.name, id: brand.id })),
                ...filteredSubCategories.map(subCategory => ({ type: 'Category', name: subCategory.name, id: subCategory.id, categoryId: subCategory.categoryId })),
                ...filteredProucCategories.map(productCategory => ({ type: 'ProductCategory', name: productCategory.name, id: productCategory.id, categoryId: productCategory.categoryId, subcategoryId: productCategory.subcategoryId }))
            ]);
        } else {
            setResults([]);
        }
    };

    const filterOptions = (options, { inputValue }) => {
        return options.filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()));
    };

    return (
        // <Box sx={{ display: 'flex', alignItems: 'center', margin: searchMargin }}>
        <Autocomplete
            id="search"
            freeSolo
            disableClearable
            clearOnBlur={false}
            options={results}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.name}
            noOptionsText="No results found"
            onInputChange={handleSearch}
            sx={{ width: searchWidth, padding: "4px 16px" }}
            onChange={(event, value, reason) => onClickSearchItem(value, reason)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search for products, brands & more"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        startAdornment: (
                            <IconButton color="inherit" aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        ),
                        sx: { ...searchInputStyles }
                    }}
                />
            )}
        />
        // </Box>
    );
};

export default Search;