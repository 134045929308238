// src/pages/Privacy.js
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Privacy = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ padding: '32px', backgroundColor: 'white' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.dark' }}>
          Privacy Policy
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6 }}>
          <strong>Introduction</strong><br />
          Welcome to OrderMyGift. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Information We Collect</strong><br />
          We collect information that you provide to us directly, such as when you create an account, place an order, or contact customer support. This may include your name, email address, phone number, shipping address, and payment information.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>How We Use Your Information</strong><br />
          We use your information to process and fulfill your orders, communicate with you about your orders and our services, and improve our offerings. We may also use your information for marketing purposes, such as sending you promotional emails, but you can opt out at any time.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Sharing Your Information</strong><br />
          We do not sell your personal information to third parties. We may share your information with trusted partners who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Security</strong><br />
          We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Your Rights</strong><br />
          You have the right to access, correct, or delete your personal information. You can also object to or restrict the processing of your data. To exercise these rights, please contact us at  Support@procurehelpdesk.com.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Changes to This Policy</strong><br />
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. You are advised to review this Privacy Policy periodically for any changes.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Contact Us</strong><br />
          If you have any questions about this Privacy Policy, please contact us at  Support@procurehelpdesk.com.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Privacy;