import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Grid, Typography, Button, Divider, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import ProductCard from '../Product/ProductCard';
import Billing from './Billing';
import EmptyCart from './EmptyCart';
import { getFilteredCartItems } from '../utils/commonUtil';

const Cart = () => {
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.carts.cart);

    const filteredCartItems = useMemo(() => getFilteredCartItems(cartItems), [cartItems]);

    if(filteredCartItems.length === 0) {
        return (
            <EmptyCart />
        );
    }

    return (
        <Container sx={{marginTop: '16px'}}>
            <Typography variant="h5" gutterBottom>
                My Cart
            </Typography>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={6}>
                    {filteredCartItems.length > 0 &&  filteredCartItems.map((product) => (
                        <ProductCard
                            key={product.id}
                            product={product}
                        />
                    ))} </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6}>
                   {filteredCartItems.length > 0 && <Billing cartItems= {filteredCartItems}/>}
                </Grid>
            </Grid>
            <Divider sx={{marginTop: '16px'}}/>
        </Container>
    );
};

export default Cart;