import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Box, Grid, Typography, Button, Card, CardMedia, IconButton, CardActions, Divider, Skeleton, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { createCart, updateCart, decrementCart } from '../../features/carts/cartSlice';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { FavoriteBorderOutlined } from '@mui/icons-material';
import { addToWishlist, removeFromWishlist } from '../../features/wishlists/wishlistSlice';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteBorder from '@mui/icons-material/FavoriteSharp';
import { fetchProductById } from '../../features/products/productsSlice';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUserOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined';
import SecurityIcon from '@mui/icons-material/SecurityOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEventsOutlined';
import { fetchVendors } from '../../features/vendors/vendorsSlice';
import ShoppingBasketOutlined from '@mui/icons-material/ShoppingCartOutlined';

const ProductDetails = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pinCode, setPinCode] = useState('');
    const [isServiceable, setIsServiceable] = useState(null);
    const [currentImage, setCurrentImage] = useState(product?.imageUrl);

    const checkServiceability = () => {
        // Example logic to check serviceability
        const serviceablePinCodes = ['201016', '201306', '201318', '201009']; // Replace with actual serviceable PIN codes
        setIsServiceable(serviceablePinCodes.includes(pinCode));
    };

    const cart = useSelector(state => state.carts.cart);
    const wishlist = useSelector(state => state.wishlists.wishlist);
    const wishlistIds = wishlist.map(item => item.id);
    const vendors = useSelector(state => state.vendors.vendors);
    const productCategories = useSelector(state => state.productCategories.productCategories);

    const dispatch = useDispatch();

    const handleAddToCart = (product) => {
        dispatch(createCart(product));
    };

    const handleIncrement = (product) => {
        dispatch(updateCart(product));
    };

    const handleDecrement = (product) => {
        const index = cart.findIndex(item => item.id === product.id);
        if (index !== -1) {
            const newCart = [...cart];
            newCart.splice(index, 1);
            dispatch(decrementCart(newCart));
        }
    };

    const handleAddToWishlist = (product) => {
        // check if the product already exists in the wishlist
        if (wishlistIds.includes(product.id)) {
            dispatch(removeFromWishlist(product.id));
            return;
        }
        dispatch(addToWishlist(product));
    };

    useEffect(() => {
        vendors.length === 0 && dispatch(fetchVendors());
        // Fetch product details from an API or mock data
        const fetchProductDetails = async () => {
            setLoading(true);
            dispatch(fetchProductById(productId)).then((response) => {
                setProduct(response.payload);
                setLoading(false);
            });
        };

        fetchProductDetails();
    }, [productId]);

    const vendor = vendors.find(vendor => vendor.id === product?.vendorId);
    const category = productCategories.find(category => category.id === product?.productCategoryId);

    const calculateSavings = (mrp, discount) => {
        return ((mrp * discount) / 100).toFixed(2);
    };

    const calculateDiscountedPrice = (mrp, discount) => {
        return ((mrp - calculateSavings(mrp, discount)).toFixed(2));
    };

    const savings = product?.price - product?.discountedPrice || calculateSavings(product?.price, product?.discount);
    const discountedPrice = product?.discountedPrice || calculateDiscountedPrice(product?.price, product?.discount);


    const aboutPoints = product?.about ? product.about.split(/[\n]/) : [];

    if (loading) {
        return (
            <Box>
                <Skeleton variant="rectangular" width="100%" height={200} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Box>
        );
    }

    return (
        <Container>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Box display="flex">
                        <Box mr={2}>
                            {product.secondaryImages ?
                                product?.secondaryImages.map((image, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        alt={product?.name}
                                        height="50"
                                        image={image}
                                        title={product?.name}
                                        sx={{
                                            objectFit: 'contain',
                                            cursor: 'pointer',
                                            maxWidth: '50%',
                                            border: '1px solid #000',
                                            borderRadius: '8px',
                                            overflow: 'hidden',
                                            mb: 1,
                                            '&:hover': {
                                                border: '4px solid #aaa',
                                            },
                                        }}
                                        onMouseEnter={() => setCurrentImage(image)}
                                        onClick={() => setCurrentImage(image)}
                                    />
                                )) : <CardMedia
                                    component="img"
                                    alt={product?.name}
                                    height="50"
                                    image={product?.imageUrl}
                                    title={product?.name}
                                    sx={{
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                        maxWidth: '50%',
                                        border: '1px solid #000',
                                        borderRadius: '8px',
                                        overflow: 'hidden',
                                        mb: 1,
                                        '&:hover': {
                                            border: '4px solid #aaa',
                                        },
                                    }}
                                    onMouseEnter={() => setCurrentImage(product.primaryImage)}
                                    onClick={() => setCurrentImage(product.primaryImage)}
                                />}
                        </Box>
                        <CardMedia
                            component="img"
                            alt={product?.name}
                            height="400"
                            image={currentImage || product?.primaryImage || product?.imageUrl}
                            title={product?.name}
                            sx={{ objectFit: 'contain', maxWidth: '80%' }}
                        />
                    </Box>
                    <CardActions sx={{ pl: 16, pr: 12, pt: 4 }}>
                        {cart?.filter(item => item.id === product?.id)?.length === 0 ? (
                            <Button
                                startIcon={<ShoppingBasketOutlined sx={{ color: 'white' }}></ShoppingBasketOutlined>}
                                variant='contained'
                                sx={{ width: '100%', height: '50px', backgroundColor: '#ff9f00' }}
                                onClick={() => handleAddToCart(product)}
                            >
                                <Typography color='white' variant="button" component="span">
                                    Add to Cart
                                </Typography>
                            </Button>) :
                            (<Box sx={{ display: 'flex', backgroundColor: '#ff9f00', borderRadius: '4px', width: '100%', height: '50px', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                <IconButton size="large" color="secondary" onClick={() => handleDecrement(product)}>
                                    <RemoveIcon fontSize='medium' />
                                </IconButton>
                                <Typography variant='h6' color={'white'} component="span">
                                    {cart?.filter(item => item.id === product.id)?.length}
                                </Typography>
                                <IconButton size="large" color="secondary" onClick={() => handleIncrement(product)}>
                                    <AddIcon fontSize='medium' />
                                </IconButton>
                            </Box>)}
                        <Button
                            startIcon={wishlistIds.includes(product?.id) ? <FavoriteBorder sx={{ color: 'white' }} /> : <FavoriteBorderIcon sx={{ color: 'white' }} />}
                            size="large"
                            variant='contained'
                            sx={{ width: '100%', height: '50px', backgroundColor: '#fb641b' }}
                            onClick={() => handleAddToWishlist(product)}
                        >
                            <Typography color='white' variant="button" component="span">
                                Wishlist
                            </Typography>
                        </Button>
                    </CardActions>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>
                        {product?.name}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {product?.description}
                    </Typography>
                    <Divider />
                    <Typography mt={2} variant="h5" color="error" gutterBottom>
                        -{product?.discount}%
                        <Typography ml={1} variant="h5" color="secondary.dark" component="span">
                            ₹{discountedPrice}
                        </Typography>
                    </Typography>

                    <Typography mt={2} variant="h6" color="textSecondary" gutterBottom>
                        MRP <b style={{ textDecoration: 'line-through' }}>₹{product?.price}</b>
                    </Typography>
                    <Typography mt={2} variant="h6" color="success.main" gutterBottom>
                        You Save: ₹{savings}
                    </Typography>
                    <Box mt={2} display="flex" columnGap={4}>
                        <Box display="flex" alignItems="center">
                            <VerifiedUserIcon />
                            <Typography ml={1}>{product?.warranty || 1} years Warranty</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <LocalShippingIcon />
                            <Typography ml={1}>Free Delivery</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <EmojiEventsIcon />
                            <Typography ml={1}>Top Brand</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <SecurityIcon />
                            <Typography ml={1}>Secure Transaction</Typography>
                        </Box>
                    </Box>
                    <Box mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            placeholder='Delivery PIN code'
                            variant='outlined'
                            value={pinCode}
                            onChange={(e) => setPinCode(e.target.value)}
                            size='small'
                            type='number'
                            sx={{ width: '30%' }}
                            InputProps={{ sx: { maxLength: 6, type: 'number', height: '30px' } }}
                        />
                        <Button size='small' variant='outlined' color="success" sx={{ ml: 2 }} onClick={checkServiceability}>
                            Check
                        </Button>
                    </Box>
                    {isServiceable !== null && (
                        <Typography mt={2} color={isServiceable ? 'green' : 'red'}>
                            {isServiceable ? 'Delivery is available for this PIN code.' : 'Delivery is not available for this PIN code.'}
                        </Typography>
                    )}
                    {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
                    <Typography fontStyle='bold' variant="h6" mb={2} mt={2}>Product Details:</Typography>
                    {vendor?.name && <Box mb={1}>
                        <Typography display="inline" fontWeight="bold">Brand Name:</Typography>
                        <Typography display="inline" ml={2}>{vendor?.name}</Typography>
                    </Box>}
                    <Box mb={1}>
                        <Typography display="inline" fontWeight="bold">Product Type:</Typography>
                        <Typography display="inline" ml={2}>{product?.productType ?? 'New'}</Typography>
                    </Box>
                    {product?.size && <Box mb={1}>
                        <Typography display="inline" fontWeight="bold">Size:</Typography>
                        <Typography display="inline" ml={2}>{product?.size ?? 'NA'}</Typography>
                    </Box>}
                    {product?.color && <Box mb={1}>
                        <Typography display="inline" fontWeight="bold">Colour:</Typography>
                        <Typography display="inline" ml={2}>{product?.color ?? 'NA'}</Typography>
                    </Box>}
                    {product?.displayTechnology && (
                        <Box mb={1}>
                            <Typography display="inline" fontWeight="bold">Display Technology:</Typography>
                            <Typography display="inline" ml={1}>{product.displayTechnology}</Typography>
                        </Box>
                    )}
                    {product?.resolution && (
                        <Box mb={1}>
                            <Typography display="inline" fontWeight="bold">Resolution:</Typography>
                            <Typography display="inline" ml={1}>{product.resolution}</Typography>
                        </Box>
                    )}
                    {product?.refreshRate && (
                        <Box mb={1}>
                            <Typography display="inline" fontWeight="bold">Refresh Rate:</Typography>
                            <Typography display="inline" ml={1}>{product.refreshRate}</Typography>
                        </Box>
                    )}
                    {product.frequencyResponse && (
                        <Box mb={1}>
                            <Typography display="inline" fontWeight="bold">Frequency Response:</Typography>
                            <Typography display="inline" ml={1}>{product.frequencyResponse}</Typography>
                        </Box>
                    )}
                    {product?.maximumOutputPower && (
                        <Box mb={1}>
                            <Typography display="inline" fontWeight="bold">Maximum Output Power:</Typography>
                            <Typography display="inline" ml={1}>{product.maximumOutputPower}</Typography>
                        </Box>
                    )}
                    {product?.productDimensions && (
                        <Box mb={1}>
                            <Typography display="inline" fontWeight="bold">Product Dimensions:</Typography>
                            <Typography display="inline" ml={1}>{product.productDimensions}</Typography>
                        </Box>
                    )}
                    {product?.supportedInternetServices && (
                        <Box mb={1}>
                            <Typography display="inline" fontWeight="bold">Supported Internet Services:</Typography>
                            <Typography display="inline" ml={1}>{product.supportedInternetServices}</Typography>
                        </Box>
                    )}
                    {product?.manufacturer && <Box mb={1}>
                        <Typography display="inline" fontWeight="bold">Manufacturer:</Typography>
                        <Typography display="inline" ml={2}>{product?.manufacturer ?? 'NA'}</Typography>
                    </Box>}
                    <Box mb={1}>
                        <Typography display="inline" fontWeight="bold">Country of Origin:</Typography>
                        <Typography display="inline" ml={2}>{product?.countryOfOrigin}</Typography>
                    </Box>
                    {product?.includedComponents && (
                        <Box mb={1}>
                            <Typography display="inline" fontWeight="bold">Included Components:</Typography>
                            <Typography display="inline" ml={1}>{product.includedComponents}</Typography>
                        </Box>
                    )}
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    <Typography variant="h6">About Product:</Typography>
                    <Typography variant="body1">
                        <ul>
                            {aboutPoints.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default ProductDetails;