import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

const NoWishList = () => {

    const navigate = useNavigate();
    return (
        <Container sx={{ marginTop: '24px' }}>
            <Typography variant="h6" component="p" align='center'>
            YOUR WISHLIST IS EMPTY
            </Typography>
            <Typography mt={4} variant="body1" component="p" align='center'>
            Add items that you like to your wishlist
            </Typography>
            <Button
                align='center'
                variant= 'outlined' 
                color= 'tertiary'
                sx={{ marginTop: '30px', marginLeft: '420px', textTransform: 'none', width: '300px'}}
                onClick={() => navigate('/home')}
            >
                <Typography variant="button" textTransform={'none'}>
                    Continue Shopping
                </Typography>
            </Button>
        </Container>
    );
}

export default NoWishList;