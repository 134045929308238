// no product found in the selected category

import React from 'react';
import { Container, Typography } from '@mui/material';

const NoProduct = () => {
    return (
        <Container sx={{ marginTop: '36px' }}>
        <Typography variant='h4' align='center'>No Product found...</Typography>
      </Container>  
    );
};

export default NoProduct;