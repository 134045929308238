import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Cancellation = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ padding: '32px', backgroundColor: 'white' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.dark' }}>
          Cancellation Policy
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6 }}>
          <strong>Order Cancellation</strong><br />
          You can cancel your order within 24 hours of placing it. To cancel your order, please contact our customer support team at  Support@procurehelpdesk.com with your order details. If your order has already been shipped, we will not be able to cancel it.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Refunds</strong><br />
          If you cancel your order within the allowed time frame, we will process a full refund to your original payment method. Please allow 5-7 business days for the refund to reflect in your account.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Non-Cancellable Items</strong><br />
          Certain items are non-cancellable and non-refundable. These include personalized items, perishable goods, and items marked as final sale. Please review the product description carefully before making a purchase.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Changes to Orders</strong><br />
          If you need to make changes to your order, such as updating the shipping address or modifying the items, please contact our customer support team as soon as possible. We will do our best to accommodate your request, but we cannot guarantee that changes can be made once the order has been processed.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Contact Us</strong><br />
          If you have any questions about our cancellation policy, please contact us at  Support@procurehelpdesk.com. We are here to help!
        </Typography>
      </Paper>
    </Container>
  );
};

export default Cancellation;