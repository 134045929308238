import React from 'react';
import { Typography } from '@mui/material';
import { List, ListItemButton, Paper } from '@mui/material';

const ProductCategories = ({ filteredProductCategories, selectedProductCategory, handleCategoryClick }) => {
    return (
        <>
            {filteredProductCategories.length > 0 && <Paper sx={{ padding: '8px' }}>
                <Typography pt={1} mb={1} variant='h6'>CATEGORIES</Typography>
                <List disablePadding>
                    {
                        filteredProductCategories.map((category) => (
                            <ListItemButton key={category.id} onClick={() => handleCategoryClick(category.id)} sx={{
                                backgroundColor: category.id === selectedProductCategory ? '#e9fdf4' : 'inherit',
                                '&:hover': {
                                    backgroundColor: '#e9fdf4', // Change this to your desired hover color
                                },
                            }}>
                                <Typography variant="body1" component="p">
                                    {category.name}
                                </Typography>
                            </ListItemButton>
                        ))
                    }
                </List>
            </Paper>}
        </>
    );
}

export default ProductCategories;