import React, { useState, useEffect } from 'react';
import { Grid, Typography, Container, Divider } from '@mui/material';
import { categories } from '../mockData';
import { useParams, useLocation } from 'react-router-dom';
import NoData from '../components/NoData';
import NoProduct from '../components/Product/NoProduct';
import ProductCategories from '../components/Product/ProductCategories';
import Brands from '../components/Product/Brands';
import Products from '../components/Product/Products';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProductCategories } from '../features/productCategories/productCategoriesSlice';
import { fetchVendors } from '../features/vendors/vendorsSlice';
import { fetchProducts } from '../features/products/productsSlice';

const ProductsPage = () => {
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedProductCategory, setSelectedProductCategory] = useState(null);

    const { categoryId, subcategoryId, vendorId, productId } = useParams();
    const categoriesFromServer = useSelector((state) => state.categories.categories);
    const products = useSelector((state) => state.products.products);
    const vendors = useSelector((state) => state.vendors.vendors);
    const productCategories = useSelector((state) => state.productCategories.productCategories);
    const dispatch = useDispatch();

    const selectedCategory = categories.find(category => category.id === parseInt(categoryId));
    const subcategoryName = selectedCategory.subcategories?.find(subcategory => subcategory.id === parseInt(subcategoryId)).name;

    const filteredVendors = vendors && vendors.filter(vendor => {
        return vendor.subcategoryId === parseInt(subcategoryId) && vendor.categoryId === parseInt(categoryId);
    });

    const filteredProductCategories = productCategories && productCategories.filter(category => category.subcategoryId === parseInt(subcategoryId) && category.categoryId === parseInt(categoryId));

    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            if (productCategories.length === 0)
                dispatch(fetchProductCategories());
            if (vendors.length === 0)
                dispatch(fetchVendors());
            if (products.length === 0)
                dispatch(fetchProducts());
        };
        fetchData();

    }, [dispatch]);

    useEffect(() => {
        // Scroll to top when the location changes
        window.scrollTo(0, 0);
    }, [location]);

    // set the filtered first vendor as selected vendor in useEffect
    useEffect(() => {
        if (vendorId) {
            setSelectedVendor(parseInt(vendorId));
        } else if (productId) {
            const product = products && products.find(product => product.id === parseInt(productId));
            setSelectedVendor(product.vendorId);
        }
        else {
            filteredVendors.length && setSelectedVendor(filteredVendors[0].id);
            filteredProductCategories.length && setSelectedProductCategory(filteredProductCategories[0].id);
        }
    }, [categoryId, subcategoryId, vendors, productCategories]);

    const handleVendorClick = (vendorId) => {
        setSelectedVendor(vendorId);
        setSelectedProductCategory(null);
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedProductCategory(categoryId);
        setSelectedVendor(null);
    };

    // if (filteredVendors.length === 0 && filteredProductCategories.length === 0) {
    //     return (
    //         <Container>
    //             <NoData />
    //         </Container>
    //     );
    // }

    const getFilteredProducts = () => {
        if (selectedProductCategory) {
            return products.filter(product => product.productCategoryId === selectedProductCategory);
        }
        else if (selectedVendor) {
            return products.filter(product => product.vendorId === selectedVendor);
        }
        return [];
    }

    const filteredProducts = getFilteredProducts();

    return (
        <Container maxWidth='xl' sx={{ marginTop: '24px' }}>
            <Grid container spacing={1} pl={8}>
                <Grid item xs={3}>
                    <Typography variant='h5'>{subcategoryName}</Typography>
                    <Typography pb={2} pt={4} variant='h6'>FILTERS</Typography>
                    <ProductCategories
                        filteredProductCategories={filteredProductCategories}
                        subcategoryName={subcategoryName}
                        selectedProductCategory={selectedProductCategory}
                        handleCategoryClick={handleCategoryClick}
                    />
                    <Divider />
                    <Brands
                        filteredVendors={filteredVendors}
                        selectedVendor={selectedVendor}
                        handleVendorClick={handleVendorClick}
                    />
                </Grid>
                <Divider />
                <Grid item xs={9}>
                    <Products
                        gridProps={{ xs: 12, sm: 6, md: 4 }}
                        filteredProducts={filteredProducts}
                        title={'Explore Our Products'}
                        nodataComponent={<NoProduct />}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default ProductsPage;