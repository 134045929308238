import { Container, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import Products from "../Product/Products";
import { useSelector } from "react-redux";
import NoWishList from "./NoWishList";

const Wishlist = () => {
    const wishlist = useSelector((state) => state.wishlists.wishlist);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <Container>
            <Products 
                filteredProducts={wishlist} 
                title={'My Wishlist'} 
                nodataComponent={<NoWishList />} 
                gridProps={isMobile ? { xs: 12 } : { xs: 12, sm: 6, md: 3 }} 
            />
        </Container>
    );
}

export default Wishlist;