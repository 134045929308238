// src/pages/Shipping.js
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Shipping = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ padding: '32px', backgroundColor: 'white' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.dark' }}>
          Shipping Policy
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6 }}>
          <strong>Order Processing</strong><br />
          All orders are processed within 1-2 business days. Orders are not shipped or delivered on weekends or holidays. If we are experiencing a high volume of orders, shipments may be delayed by a few days. Please allow additional days in transit for delivery. If there will be a significant delay in the shipment of your order, we will contact you via email or telephone.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Shipping Rates & Delivery Estimates</strong><br />
          Shipping charges for your order will be calculated and displayed at checkout. Delivery delays can occasionally occur.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Shipment Confirmation & Order Tracking</strong><br />
          You will receive a Shipment Confirmation email once your order has shipped containing your tracking number(s). The tracking number will be active within 24 hours.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Customs, Duties, and Taxes</strong><br />
          OrderMyGift is not responsible for any customs and taxes applied to your order. All fees imposed during or after shipping are the responsibility of the customer (tariffs, taxes, etc.).
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Damages</strong><br />
          OrderMyGift is not liable for any products damaged or lost during shipping. If you received your order damaged, please contact the shipment carrier to file a claim. Please save all packaging materials and damaged goods before filing a claim.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>International Shipping Policy</strong><br />
          We currently do not ship outside the India
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Returns Policy</strong><br />
          Our Return & Refund Policy provides detailed information about options and procedures for returning your order.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Shipping;