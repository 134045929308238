import React from 'react';
import { Paper, Typography, Button, Divider, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Billing = ({ cartItems, showButton = true }) => {

    const navigate = useNavigate();

    const subtotal = cartItems.reduce((acc, item) => acc + item.discountedPrice * item.quantity, 0);

    return (
        <Paper elevation={0} style={{ padding: '20px', maxWidth: '400px', margin: '20px auto', backgroundColor: '#F0FFFF' }}>
            <Typography variant="h5" gutterBottom>
                Order Summery
            </Typography>
            <Divider sx={{ margin: '10px 0' }} />
            <Box container spacing={2}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" component="p">
                        Subtotal:
                    </Typography>
                    <Typography variant="body1" component="p">
                        ₹{subtotal.toFixed(2)}
                    </Typography>
                </Box>
                {/* <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" component="p">
                        GST (18%):
                    </Typography>
                    <Typography variant="body1" component="p">
                        ₹{gstAmount.toFixed(2)}
                    </Typography>
                </Box> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" component="p">
                        Platform Fee:
                    </Typography>
                    <Typography variant="body1" component="p">
                        ₹{0}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" component="p">
                        Shipping Fee:
                    </Typography>
                    <Typography variant="body1" component="p">
                        ₹{0}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" component="p">
                        Grand Total:
                    </Typography>
                    <Typography variant="body1" component="p">
                        ₹{subtotal.toFixed(2)}
                    </Typography>
                </Box>
            </Box>
            {showButton && <Button
                variant="contained"
                color="tertiary"
                sx={{ marginTop: '24px' }}
                fullWidth
                onClick={() => navigate('/checkout')}
            >
                 <Typography variant="button" color="white" textTransform={'none'}>
                    Proceed to Checkout
                </Typography>
            </Button>}
        </Paper>
    );
};

export default Billing;