import React from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { updateCart, decrementCart } from '../../features/carts/cartSlice';

const ProductCard = ({ product }) => {

    const totalPrice = product.discountedPrice * product.quantity;

    const cart = useSelector((state) => state.carts.cart);

    const dispatch = useDispatch();

    const handleIncrement = (item) => {
        dispatch(updateCart({ ...item, quantity: item.quantity + 1 }));
    };

    const handleDecrement = (product) => {
        const index = cart.findIndex(item => item.id === product.id);
        if (index !== -1) {
            const newCart = [...cart];
            newCart.splice(index, 1);
            dispatch(decrementCart(newCart));
        }
    };


    return (
        <Box container spacing={2} mt={2}>
        <Card elevation={0} sx={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#F0FFFF'}}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={6}>
            <CardMedia
                component="img"
                image={product.primaryImage}
                alt={product.name}
                sx={{ width: 250, height: 250, objectFit: 'contain', padding: '10px' }}
            />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={6}>
            <CardContent sx={{ flex: 1, marginLeft: '2em' }}>
                <Typography variant="h6">
                    {product.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {product.description}
                </Typography>
                <Typography variant="body1">
                    Price: ₹{product?.discountedPrice}
                </Typography>
                <Box sx={{borderRadius: '4px', maxWidth: 'fit-content', display: 'flex', alignItems: 'center', marginTop: '10px', backgroundColor: 'green' }}>
                    <IconButton size="medium" color="secondary" onClick={() => handleDecrement(product)}>
                        <RemoveIcon sx={{ width: '16px', height: '16px' }} />
                    </IconButton>
                    <Typography variant='body2' color={'white'} component="span">
                        {cart?.filter(item => item.id === product.id)?.length}
                    </Typography>
                    <IconButton size="medium" color="secondary" onClick={() => handleIncrement(product)}>
                        <AddIcon sx={{ width: '16px', height: '16px' }} />
                    </IconButton>
                </Box>
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                    Total Price: ₹{totalPrice}
                </Typography>
            </CardContent>
            </Grid>
            </Grid>
        </Card>
        </Box>
    );
};

export default ProductCard;