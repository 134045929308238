import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

const EmptyCart = () => {

    const navigate = useNavigate();
    return (
        <Container sx={{ marginTop: '24px' }}>
            <Box align='center' sx={{width: '150px', height: '150px',  marginLeft: '500px'}} component= 'img' src= {process.env.PUBLIC_URL + '/emptycart.jpg'} alt="empty cart" />
            <Typography variant="h6" component="p" align='center'>
                You don't have any items in your cart
            </Typography>
            <Button
                align='center'
                variant='contained' 
                color='tertiary'
                sx={{ marginTop: '30px', marginLeft: '420px', textTransform: 'none', width: '300px', backgroundColor: 'green' }}
                onClick={() => navigate('/home')}
            >
                <Typography variant="button" color="white" textTransform={'none'}>
                    Continue Shopping
                </Typography>
            </Button>
        </Container>
    );
}

export default EmptyCart;