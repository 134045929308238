import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    Button,
    TextField as MuiTextField, Container, Box, Grid, Typography, CircularProgress, Snackbar, Alert, Paper,
    Radio, RadioGroup, FormControl, FormControlLabel, Avatar, InputAdornment
} from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { addUser } from '../../features/users/usersSlice';
import { useNavigate } from 'react-router-dom';
import { AccountCircle, Business, Discount, Email, Phone } from '@mui/icons-material';

// Styled components
const StyledBox = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTextField = styled(MuiTextField)`
  && {
    margin: 8px 0; // Adjust margin as needed
    width: 100%; // Ensures full width

    &:focus {
      border-color: red; // Example: Change border color on focus
    }
  }
`;

function Registration() {
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const [personalEmail, setPersonalEmail] = useState('');
    const [corporateEmail, setCorporateEmail] = useState('');
    const [isCorporateUser, setIsCorporateUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.users);

    const onSubmit = async (data) => {
        const formData = {
            ...data,
            email: isCorporateUser ? corporateEmail : personalEmail,
            isCorporateUser: isCorporateUser
        }
        setLoading(true);
        // Simulate an API call to submit the form
        dispatch(addUser(formData)).then((response) => {
            if (response.meta.requestStatus === 'fulfilled') {
                if (response.payload) {
                    setSnackbarMessage(response.payload.message);
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    setLoading(false);
                    navigate('/');
                }
            }
        }).catch((error) => {
            setSnackbarMessage(error.message);
            console.error(error);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        }
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const validateCorporateEmail = (email) => {
        const restrictedDomains = ['gmail.com', 'outlook.com', 'hotmail.com', 'yahoo.com', 'rediffmail.com'];
        const domain = email.split('@')[1];
        return !restrictedDomains.includes(domain) || 'Invalid corporate email address';
    };

    const validatePersonalEmail = (email) => {
        const allowedDomains = ['gmail.com', 'outlook.com', 'hotmail.com', 'yahoo.com', 'rediffmail.com'];
        const domain = email.split('@')[1];
        return allowedDomains.includes(domain) || 'Invalid personal email address';
    };

    const validateCouponCode = (code) => {

        const validCoupens = ['CRO0001', 'GC0001', 'GZ0001'];
        // Add your coupon code validation logic here
        return validCoupens.includes(code) || 'Invalid coupon code';
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={0}
                sx={{
                    padding: '1rem',
                    marginTop: '1rem',
                    backgroundColor: '#F0FFFF',
                }}>

                <Avatar sx={{ width: 100, height: 100, margin: '0 auto', fontSize: 100 }} src={`${process.env.PUBLIC_URL}/user.png`} />
                <Typography mb={2} textTransform='uppercase' align='center' variant="h5" component="h1" color='primary.dark' gutterBottom>
                    Register now
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <Typography color='primary.dark' variant="h6" component="legend">Are you a corporate user?</Typography>
                                <RadioGroup
                                    row
                                    aria-label="corporateUser"
                                    name="corporateUser"
                                    value={isCorporateUser ? 'yes' : 'no'}
                                    onChange={(e) => setIsCorporateUser(e.target.value === 'yes')}
                                    sx={{ color: 'primary.dark' }}
                                >
                                    <FormControlLabel value="yes" control={<Radio color='success' />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio color='success' />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle fontSize='medium' />
                                        </InputAdornment>
                                    ),
                                }}
                                label="First Name"
                                margin="normal"
                                fullWidth
                                {...register("firstName", { required: "First Name is required" })}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle fontSize='medium' />
                                        </InputAdornment>
                                    ),
                                }}
                                label="Last Name"
                                margin="normal"
                                fullWidth
                                {...register("lastName", { required: "Last Name is required" })}
                                error={!!errors.lastName}
                                helperText={errors.lastName?.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <StyledTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Phone fontSize='medium' />
                                        </InputAdornment>
                                    ),
                                }}
                                label="Phone Number"
                                margin="normal"
                                fullWidth
                                {...register("phone", { required: "Phone Number is required" })}
                                error={!!errors.phone}
                                helperText={errors.phone?.message}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                        <StyledTextField
                            label="Email"
                            type="email"
                            margin="normal"
                            fullWidth
                            {...register("email", { required: "Email is required" })}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                    </Grid> */}
                        {isCorporateUser ? (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <StyledTextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Email fontSize='medium' />
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Corporate Email Address"
                                        type="email"
                                        margin="normal"
                                        fullWidth
                                        {...register("corporateEmail", {
                                            required: "Corporate Email Address is required",
                                            validate: validateCorporateEmail
                                        })}
                                        onBlur={(e) => {
                                            trigger("corporateEmail");
                                            setCorporateEmail(e.target.value);
                                        }}
                                        error={!!errors.corporateEmail}
                                        helperText={errors.corporateEmail?.message}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <StyledTextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Business fontSize='medium' />
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Organization Name"
                                        margin="normal"
                                        fullWidth
                                        {...register("organizationName", { required: "Organization Name is required" })}
                                        error={!!errors.organizationName}
                                        helperText={errors.organizationName?.message}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <StyledTextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Email fontSize='medium' />
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Personal Email Address"
                                        type="email"
                                        margin="normal"
                                        fullWidth
                                        {...register("personalEmail", {
                                            required: "Personal Email Address is required",
                                            validate: validatePersonalEmail
                                        })}
                                        onBlur={(e) => {
                                            trigger("personalEmail");
                                            setPersonalEmail(e.target.value);
                                        }}
                                        error={!!errors.personalEmail}
                                        helperText={errors.personalEmail?.message}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <StyledTextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Discount fontSize='medium' />
                                                </InputAdornment>
                                            ),
                                        }}
                                        label="Coupon Code"
                                        margin="normal"
                                        fullWidth
                                        {...register("couponCode", {
                                            required: "Coupon Code is required",
                                            validate: validateCouponCode
                                        })}
                                        onBlur={() => trigger("couponCode")}
                                        error={!!errors.couponCode}
                                        helperText={errors.couponCode?.message}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="center" mt={2}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    size="large"
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24} /> : 'Register'}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
                <Grid display='flex' alignItems='center' item mt={2}>
                    <Typography align='center' color='primary.dark' variant="body2" component="a" href="otplogin" sx={{ textDecoration: 'none' }}>
                        {"Already have an account? Sign in"}
                    </Typography>
                </Grid>
            </Paper>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container >
    );
}

export default Registration;