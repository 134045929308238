// src/pages/Refund.js
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Refund = () => {
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ padding: '32px', backgroundColor: 'white' }}>
                <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.dark' }}>
                    Refund Policy
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6 }}>
                    <strong>1. Eligibility for Refunds</strong><br />
                    Refunds are available for items returned within <strong>30 days</strong> of the purchase date.<br />
                    Items must be in <strong>original condition</strong>, unused, and with all original packaging and tags.<br />
                    Proof of purchase (receipt or order number) is required for all refunds.
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
                    <strong>2. Non-Refundable Items</strong><br />
                    <strong>Perishable goods</strong> (e.g., food, flowers).<br />
                    <strong>Personal care items</strong> (e.g., cosmetics, hygiene products) once opened.<br />
                    <strong>Gift cards</strong> and downloadable software products.<br />
                    <strong>Sale items</strong> and items marked as "Final Sale."
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
                    <strong>3. How to Request a Refund</strong><br />
                    Contact our <strong>customer support</strong> team via email at <a href="mailto:Support@procurehelpdesk.com">Support@procurehelpdesk.com</a>.<br />
                    Provide your <strong>order number</strong> and reason for the refund request.<br />
                    Once approved, you will receive instructions on how to return the item.
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
                    <strong>4. Processing of Refunds</strong><br />
                    Once we receive and inspect your returned item, we will notify you via email.<br />
                    Approved refunds will be processed within <strong>7-10 business days</strong>.<br />
                    The refund will be applied to your original method of payment.
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
                    <strong>5. Shipping Costs</strong><br />
                    <strong>Return shipping costs</strong> are the responsibility of the customer, unless the return is due to an error on our part.<br />
                    If the item is defective or damaged, we will cover the return shipping costs.
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
                    <strong>6. Late or Missing Refunds</strong><br />
                    If you haven’t received a refund within <strong>10 business days</strong>, first check your bank account again.<br />
                    Then contact your credit card company; it may take some time before your refund is officially posted.<br />
                    If you’ve done all of this and still have not received your refund, please contact us at <a href="mailto:Support@procurehelpdesk.com">Support@procurehelpdesk.com</a>.
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
                    <strong>7. Exchanges</strong><br />
                    We only replace items if they are defective or damaged. If you need to exchange it for the same item, please contact our customer support team.
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
                    <strong>8. Changes to This Policy</strong><br />
                    We reserve the right to update or modify this Refund Policy at any time. Changes will be posted on our website with the date of revision.
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
                    <strong>9. Contact Information</strong><br />
                    For more information about our refund policy, please contact us:<br />
                    <strong>Email:</strong> <a href="mailto:Support@procurehelpdesk.com">Support@procurehelpdesk.com</a><br />
                </Typography>
            </Paper>
        </Container>
    );
};

export default Refund;