import React from 'react';
import { Container, Box, Paper, Typography } from '@mui/material';
import EmailVerificationButton from './EmailVerification';
import PhoneNumberVerification from './PhoneNumberVerification';

const OtpLogin = () => {
  
  return (
    <Container maxWidth="sm">
      <Paper elevation={0} sx={{ padding: 3, marginTop: 5, backgroundColor: 'azure' }}>
        <Typography  variant="h6" component="h1" align="center">
        Welcome to OrderMyGift
        </Typography>
        <Typography mt = {2} variant="body1" align="center">
          Please verify your email address or phone number to continue.
        </Typography>
      <Box sx={{ mt: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <EmailVerificationButton />
        <PhoneNumberVerification/>
      </Box>
      </Paper>
    </Container>
  );
};

export default OtpLogin;