import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Container, IconButton, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { Carousel } from 'react-responsive-carousel';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ShopByCategory = ({ categories }) => {
    const navigate = useNavigate();

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const categoryBoxStyle = isMobile ? { width: '150px', height: '110px' } : { width: '180px', height: '320px' };

    const carMediaStyles = isMobile ? { width: 150, height: 50, padding: '4px' } : { width: '100%', height: 200 };

    const subcategories = categories.reduce((acc, category) => {
        return [...acc, ...category.subcategories.map(subcategory => ({ ...subcategory, categoryId: category.id }))];
    }, []);

    return (
        <Container maxWidth={isMobile ? 'sm' : 'lg'} sx={{ marginLeft: '30px' }}>
            <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ fontWeight: 'bold', padding: '34px', marginTop: '24px', textAlign: 'left', textTransform: 'uppercase'  }}>Shop By Category</Typography>
            <Box gap={4} mt={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {subcategories.map((subcategory, index) => (
                    <Box  key={`${subcategory.id}-${index}`} sx={{ ...categoryBoxStyle }}>
                        <Card
                            elevation={0}
                            sx={
                                {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '0',
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                    transition: 'box-shadow 0.10s ease-in-out, transform 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: 6, // Elevation level on hover
                                        transform: 'scale(1.02)', // Scale effect on hover
                                        transition: 'box-shadow 0.10s ease-in-out, transform 0.3s ease-in-out',
                                    },
                                }}
                            onClick={() => navigate(`/products/${subcategory.categoryId}/${subcategory.id}`)}
                        >
                            <CardMedia
                                component="img"
                                image={subcategory.imageUrl}
                                alt={subcategory.name}
                                sx={{ ...carMediaStyles, objectFit: 'fill' }}
                            />
                            <CardContent sx={
                                {
                                    flex: 1,
                                    alignItems: 'center',
                                    backgroundColor: 'tertiary.dark',
                                    // backgroundImage: 'linear-gradient(90deg, #097969, #CC5500)',
                                    // backgroundImage: process.env.PUBLIC_URL + 'bg.png',
                                    width: '100%',
                                    paddingTop: 0,
                                    '&:last-child': {
                                        paddingBottom: '4px',
                                    },
                                    height: '100px',
                                }}>
                                <Typography color={'white'} fontWeight={'bold'} textAlign={'center'} variant={isMobile ? "caption" : "body2"}>
                                    {subcategory.name}
                                </Typography>
                                <Typography color={'white'} component={'p'} lineHeight={'16px'} fontWeight={'bold'} textAlign={'center'} variant={isMobile ? "caption" : 'caption'}>
                                    40 - 60% OFF
                                </Typography>
                                <Typography color={'white'} fontWeight={'bold'} textAlign={'center'} variant={isMobile ? "caption" : "body2"}>
                                    Shop Now
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                ))}
            </Box>
        </Container >
    );
}

export default ShopByCategory;