import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import Cookies from "js-cookie";

const initialState = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    address: {
      street: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    },
    isCorporate: false,
    phone: "",
    token: "",
    authenticated: false,
  },
  status: "idle",
  loading: false,
};

export const checkUser = createAsyncThunk(
  "user/checkUser",
  async (userData) => {
    const response = await axiosConfig.post("/api/user/check", userData);
    return response.data;
  }
);

export const addUser = createAsyncThunk("user/addUser", async (userData) => {
  const response = await axiosConfig.post("/api/user/register", userData);
  return response.data;
});

export const authenticateUser = createAsyncThunk(
  "user/authenticateUser",
  async (userData) => {
    const response = await axiosConfig.post("/api/user/login", userData);
    return response.data;
  }
);
export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (userData) => {
    const response = await axiosConfig.get("/api/user/getInfo");
    return response.data;
  }
);

export const logoutUser = createAsyncThunk(
  "user/logoutUser",
  async () => {
    const response = await axiosConfig.get("/api/user/logout");
    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (userData) => {
    const response = await axiosConfig.put("/api/user/update", userData);
    return response.data;
  }
);

export const setUserToken = createAction("user/setUserToken", (payload) => {
  return {
    payload: payload,
  };
});


const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(addUser.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(authenticateUser.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
        Cookies.set("Authorization", action.payload.token, { expires: 1 / 24 });
        state.status = "succeeded";
        state.loading = false;
        state.user.authenticated = true;
      })
      .addCase(authenticateUser.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(authenticateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
        state.user.authenticated = false;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
        state.status = "succeeded";
        state.loading = false;
      })
      .addCase(getUserDetails.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.user = { ...state.user, authenticated: false };
        state.status = "succeeded";
        Cookies.remove("Authorization");
        state.loading = false;
        state.user.authenticated = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
        state.user.authenticated = false;
      }).addCase(setUserToken, (state, action) => {
        state.user.token = action.payload.token;
        state.user.authenticated = action.payload.authenticated;
      }).addCase(updateUser.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
        state.status = "succeeded";
        state.loading = false;
      }
      )
      .addCase(updateUser.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default userSlice.reducer;