// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import productsReducer from './features/products/productsSlice';
import cartReducer from './features/carts/cartSlice';
import wishlistReducer from './features/wishlists/wishlistSlice';
import usersReducer from './features/users/usersSlice';
import categoriesReducer from './features/categories/categoriesSlice';
import vendorsReducer from './features/vendors/vendorsSlice';
import productCategoriesReducer from './features/productCategories/productCategoriesSlice';

const store = configureStore({
  reducer: {
    products: productsReducer,
    carts: cartReducer,
    wishlists: wishlistReducer,
    users: usersReducer,
    categories: categoriesReducer,
    vendors: vendorsReducer,
    productCategories: productCategoriesReducer,
  },
});

export default store;