import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Box, Skeleton, ListItemText, Button, Typography, ListItemButton, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './NavBar.css'; // Import the CSS file

const NavBar = ({ categories }) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (categories && categories.length > 0) {
            setLoading(false);
        }
    }, [categories]);

    const hadleMenuItemClick = (category, subcategory) => {
        navigate(`/products/${category.id}/${subcategory.id}`);
    }

    const appBarClass = isMobile ? { padding: '0px 8px', width: '95vw', overflowX: 'auto' } : { padding: '0px 16px', width: '100%' };

    return (
        <AppBar sx={{ ...appBarClass }} position="static" elevation={0} color='inherit'>
            <Toolbar>
                <Box sx={{ display: 'flex', flexGrow: 1, gap: '24px' }}>
                    {loading ? <>
                        <Skeleton animation='wave' variant="text" width={100} height={40} />
                        <Skeleton animation='wave' variant="text" width={100} height={40} />
                        <Skeleton animation='wave' variant="text" width={100} height={40} />
                        <Skeleton animation='wave' variant="text" width={100} height={40} />
                        <Skeleton animation='wave' variant="text" width={100} height={40} />
                        <Skeleton animation='wave' variant="text" width={100} height={40} />
                    </> :
                        categories.map((category) => (
                            <Box
                                key={category.name}
                                className="nav-item"
                            >
                                <Typography variant='subtitle2' color="inherit" sx={{ cursor: 'pointer', textTransform: 'uppercase' }}>
                                    {category.name}
                                </Typography>
                                <Box className="submenu">
                                    {category.subcategories.map((subcategory) => (
                                        <ListItemButton sx={{
                                            '&:hover': {
                                                backgroundColor: '#e9fdf4',
                                            }
                                        }} className="submenu-item" onClick={() => hadleMenuItemClick(category, subcategory)} key={subcategory.id}>
                                            <Typography className="submenu-item" variant='subtitle2' color="secondary.dark" textTransform='none'>
                                                {subcategory.name}
                                            </Typography>
                                        </ListItemButton>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                </Box>
            </Toolbar>
        </AppBar >
    );
};

export default NavBar;
