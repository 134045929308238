import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { authenticateUser, checkUser, getUserDetails } from "../../features/users/usersSlice";
import { Box, CircularProgress } from "@mui/material";
import { set } from "react-hook-form";

const EmailVerificationButton = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [verifiedEmail, setVerifiedEmail] = useState('');

    const userObj = useSelector((state) => state.users.user);

    useEffect(() => {
        if (userObj && userObj.token && userObj.authenticated) {
            dispatch(getUserDetails());
            navigate('/');
        }
    }, [userObj]);

    useEffect(() => {
        // Load the script for the phone/email sign-in button
        const script = document.createElement("script");
        script.src = "https://www.phone.email/verify_email_v1.js";
        script.async = true;
        document.body.appendChild(script);

        // Define the listener function
        window.phoneEmailReceiver = async (userObj) => {
            const { user_email_id } = userObj;
            if (user_email_id) {
                setIsEmailVerified(true);
                setVerifiedEmail(user_email_id);
            }
        };

        // Cleanup script and listener on component unmount
        return () => {
            // Cleanup the listener function when the component unmounts
            window.phoneEmailListener = null;
        };
    }, []);

    useEffect(() => {
        if (isEmailVerified) {
            setLoading(true);
            dispatch(checkUser({ email: verifiedEmail }))
                .then((response) => {
                    window.phoneEmailListener = null;
                    if (response.payload?.isUserExists === true) {
                        dispatch(authenticateUser({ email: verifiedEmail })).then(() => {
                            setLoading(false);
                        });
                    } else {
                        setLoading(false);
                        navigate('/register')
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [isEmailVerified]);

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <CircularProgress color="success"/>
            </Box>
        );
    }


    return (
        <div className="pe_verify_email" data-client-id="14685266978639066039"></div>
    );
};

export default EmailVerificationButton;
