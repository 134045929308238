// src/features/products/productsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../axiosConfig';

const initialState = {
  products: [],
  status: 'idle',
  error: null,
  createProductStatus: false,
};

export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  const response = await axiosConfig.get('/api/products');
  return response.data;
});

export const fetchProductById = createAsyncThunk('products/fetchProductById', async (productId) => {
  const response = await axiosConfig.get('/api/products', {
    params: { id: productId }
  });
  return response.data;
});

export const createProduct = createAsyncThunk('products/createProduct', async (product) => {
  const response = await axiosConfig.post('/api/products', product);
  return response.data;
});


const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.createProductStatus = true;
      }).addCase(createProduct.rejected, (state, action) => {
        state.error = action.error.message;
      }
      );
  },
});

export default productsSlice.reducer;