import axios from 'axios';
import Cookies from 'js-cookie';

// Function to fetch the configuration file
const fetchConfig = async () => {
  const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
  const config = await response.json();
  Cookies.set('API_BASE_URL', config.API_BASE_URL);
  Cookies.set('IMAGE_BASE_URL', config.IMAGE_BASE_URL);
  return config;
};

const config = await fetchConfig();
const baseURL = config.API_BASE_URL;

// Create an Axios axiosConfig with a base URL
const axiosConfig = axios.create({
  baseURL: baseURL,
  // withCredentials: true,
});

// Function to retrieve your token
export const getToken = () => {
  return Cookies.get('Authorization') || null;
};

// Add a request interceptor
axiosConfig.interceptors.request.use(
  config => {
    // config.withCredentials = true;
    if(config.url === '/api/user/login' || config.url === '/api/user/register' || config.url === '/api/user/check') {
      return config;
    }
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosConfig;