import React, { useState } from 'react';
import { AppBar, Toolbar, Box, Chip, IconButton, Menu, MenuItem, Card, CardMedia, CardContent, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const CategoryNavBarMobile = ({ categories }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleMenuItemClick = (category) => {
        setSelectedCategory(category);
        handleClose();
    };

    const handleChipClick = (category) => {
        setSelectedCategory(category);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onCardClick = (subcategory) => {
        navigate(`/products/${selectedCategory.id}/${subcategory.id}`);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar position="static" elevation={0} color='inherit'>
                <Toolbar>
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                        {categories.slice(0, 3).map((category) => (
                            <Chip
                                key={category.id}
                                label={category.name}
                                onClick={() => handleChipClick(category)}
                                sx={{
                                    cursor: 'pointer', textTransform: 'none',
                                    backgroundColor: selectedCategory?.id === category.id ? 'primary.dark' : 'default',
                                    color: selectedCategory?.id === category.id ? 'white' : 'black'
                                }}
                            />
                        ))}
                        {categories.length > 3 && (
                            <>
                                <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {categories.slice(3).map((category) => (
                                        <MenuItem
                                            key={category.id}
                                            onClick={() => handleMenuItemClick(category)}
                                        >
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            {selectedCategory && (
                <Box sx={{ display: 'flex', gap: '4px', padding: '0 16px' }}>
                    {selectedCategory.subcategories.map((subcategory) => (
                        <Card key={subcategory.id} sx={{ width: 50, height: 50 }}
                            onClick={() => onCardClick(subcategory)}>
                            <CardMedia
                                component="img"
                                height="30"
                                image={subcategory.imageUrl}
                                imageUrl={subcategory.imageUrl}
                                alt={subcategory.name}
                                sx={{ objectFit: 'scale-down' }}
                            />
                            <Box p={0} pl={1} pr={1} display={'flex'} component={'caption'}>
                                <Typography sx={{ alignSlef: 'center' }} variant='caption'>
                                    {subcategory.name}
                                </Typography>
                            </Box>
                        </Card>
                    ))}
                </Box>
            )}
        </>
    );
};

export default CategoryNavBarMobile;