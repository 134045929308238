import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProduct } from '../features/products/productsSlice';
import { fetchVendors } from '../features/vendors/vendorsSlice';
import { fetchProductCategories } from '../features/productCategories/productCategoriesSlice';
import { TextField, Button, Container, Typography, Box, Snackbar, Alert, Select, MenuItem, FormControl, InputLabel, Card, CardContent, Grid } from '@mui/material';
import styled from '@mui/system/styled';
import { categories, countryOfOrigin, imageFormats, productTypes } from '../mockData';

const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#8bc34a', // Border color on focus
        },
        '& .MuiInputLabel-root': {
            color: 'initial', // Default label color
        },
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'initial', // Label color on focus
    },
});

const CustomSelect = styled(Select)({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#8bc34a', // Border color on focus
    },
    '& .MuiInputLabel-root': {
        color: 'initial', // Default label color
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'initial', // Label color on focus
    },
});

const AddProduct = () => {
    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedSubcategory, setSelectedSubcategory] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const [product, setProduct] = useState({
        name: '',
        description: '',
        imageName: '',
        vendorId: 0,
        price: '',
        productCategoryId: 0,
        discountedPrice: '',
        size: '',
        color: '',
        manufacturer: '',
        vendor: '',
        countryOfOrigin: 'India',
        secondaryImages: [],
        imageFormat: '',
        productType: '',
        about: '',
        refreshRate: '',
        productDimensions: '',
        displayTechnology: '',
        resolution: '',
        includedComponents: '',
        supportedInternetServices: '',
        capacity: '',
        warranty: '',
    });

    // const [imageFile, setImageFile] = useState(null);
    const vendors = useSelector(state => state.vendors.vendors);
    const productCategories = useSelector(state => state.productCategories.productCategories);
    const createProductStatus = useSelector(state => state.products.createProductStatus);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setSelectedSubcategory(''); // Reset subcategory when category changes
    };

    const handleSubcategoryChange = (event) => {
        setSelectedSubcategory(event.target.value);
    };

    const filteredSubcategories = selectedCategory?.subcategories;

    // Filter vendors and product categories based on selected subcategory
    const filteredVendors = vendors?.filter(
        (vendor) => vendor.subcategoryId === selectedSubcategory && vendor.categoryId === selectedCategory?.id
    );

    const filteredProductCategories = productCategories?.filter(
        (category) => category.subcategoryId === selectedSubcategory && category.categoryId === selectedCategory?.id
    );

    useEffect(() => {
        dispatch(fetchVendors());
        dispatch(fetchProductCategories());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct({
            ...product,
            [name]: value
        });
    };

    // const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         setSelectedImage(file);
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImagePreviewUrl(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // let imageUrl = '';
        // if (selectedImage) {
        //     const formData = new FormData();
        //     formData.append('file', selectedImage);
        //     formData.append('upload_preset', 'your_upload_preset'); // Replace with your upload preset

        //     const response = await fetch('https://api.cloudinary.com/v1_1/your_cloud_name/image/upload', { // Replace with your image upload URL
        //         method: 'POST',
        //         body: formData
        //     });

        //     const data = await response.json();
        //     imageUrl = data.secure_url;
        // }

        const productData = {
            ...product,
            secondaryImages: product.secondaryImages?.length > 0 ? product.secondaryImages?.split(',').map((image) => image.trim()) : [],
        };

        const createProductResponse = await dispatch(createProduct(productData));
        setOpen(true);
        if (createProductResponse.meta.requestStatus === 'fulfilled') {
            setProduct({
                name: '',
                description: '',
                vendorId: '',
                price: '',
                imageName: '', // Reset the image URL
                productCategoryId: '',
                discountedPrice: '',
                size: '',
                color: '',
                manufacturer: '',
                vendor: '',
                countryOfOrigin: 'India',
                secondaryImages: [],
                imageFormat: '',
                productType: '',
                about: '',
                refreshRate: '',
                productDimensions: '',
                displayTechnology: '',
                resolution: '',
                includedComponents: '',
                supportedInternetServices: '',
                capacity: '',
            });
        }

        // setSelectedImage(null); // Reset the selected image
        // setImagePreviewUrl(''); // Reset the image preview URL
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 4 }}>
                <Typography variant="h5" component="h1" gutterBottom align='center'>
                    Add New Product
                </Typography>
                <Card elevation={0}>
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        value={product.name}
                                        onChange={handleChange}
                                        required
                                        placeholder='e.g. Samsung refrigerator 256L'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        label="Description"
                                        name="description"
                                        value={product.description}
                                        onChange={handleChange}
                                        required
                                        placeholder='e.g. Capacity of 256 litres and comes with a 1-year warranty'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        label="About"
                                        name="about"
                                        multiline
                                        value={product.about}
                                        onChange={handleChange}
                                        required
                                        placeholder='Each point should be seperated by new line'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        label="Image Name"
                                        name="imageName"
                                        multiline
                                        value={product.imageUrl}
                                        onChange={handleChange}
                                        required
                                        placeholder='e.g. samsung-refrigerator-256L'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        label="Secondary Images"
                                        name="secondaryImages"
                                        multiline
                                        value={product.secondaryImages}
                                        onChange={handleChange}
                                        placeholder='e.g. image1.jpg, image2.jpg, image3.jpg'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="type-label">Image Format</InputLabel>
                                        <CustomSelect
                                            labelId="type-label"
                                            name='imageFormat'
                                            value={product.imageFormat}
                                            onChange={handleChange}
                                            displayEmpty
                                            required
                                            label="Image Format"
                                        >
                                            {imageFormats.map((format, index) => (
                                                <MenuItem key={index} value={format}>
                                                    {format}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomTextField
                                        fullWidth
                                        label="Size"
                                        name="size"
                                        value={product.size}
                                        onChange={handleChange}
                                        placeholder='e.g. 256L'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomTextField
                                        fullWidth
                                        label="Capacity"
                                        name="capacity"
                                        value={product.capacity}
                                        onChange={handleChange}
                                        placeholder='e.g. 256L'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomTextField
                                        fullWidth
                                        label="Refresh Rate"
                                        name="refreshRate"
                                        value={product.refreshRate}
                                        onChange={handleChange}
                                        placeholder='e.g. 60 Hz'
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <CustomTextField
                                        fullWidth
                                        label="Product Dimensions"
                                        name="productDimensions"
                                        value={product.productDimensions}
                                        onChange={handleChange}
                                        placeholder='e.g. 146.7 x 71.5 x 7.4 mm (5.78 x 2.81 x 0.29 in)'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomTextField
                                        fullWidth
                                        label="Display Technology"
                                        name="displayTechnology"
                                        value={product.displayTechnology}
                                        onChange={handleChange}
                                        placeholder='e.g. OLED'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomTextField
                                        fullWidth
                                        label="Resolution"
                                        name="resolution"
                                        value={product.resolution}
                                        onChange={handleChange}
                                        placeholder='e.g. 2778 x 1284 pixels'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        label="Included Components"
                                        name="includedComponents"
                                        value={product.includedComponents}
                                        onChange={handleChange}
                                        placeholder='e.g User Manual'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        label="Supported Internet Services"
                                        name="supportedInternetServices"
                                        value={product.supportedInternetServices}
                                        onChange={handleChange}
                                        placeholder='e.g. Netflix, Prime Video, SonyLiv, Hotstar'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomTextField
                                        fullWidth
                                        label="Color"
                                        name="color"
                                        value={product.color}
                                        onChange={handleChange}
                                        placeholder='e.g. Black'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <CustomTextField
                                            fullWidth
                                            label="Warranty (in years)"
                                            name="warranty"
                                            value={product.warranty}
                                            onChange={handleChange}
                                            type='number'
                                            placeholder='e.g. 1'
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="type-label">Product Type</InputLabel>
                                        <CustomSelect
                                            labelId="type-label"
                                            name='productType'
                                            value={product.productType}
                                            onChange={handleChange}
                                            displayEmpty
                                            label="Product Type"
                                        >
                                            {/* <MenuItem value="" disabled>
                                                <em>Select a category</em>
                                            </MenuItem> */}
                                            {productTypes.map((type, index) => (
                                                <MenuItem key={index} value={type}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="category-label">Category</InputLabel>
                                        <CustomSelect
                                            labelId="category-label"
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            displayEmpty
                                            label="Category"
                                        >
                                            {/* <MenuItem value="" disabled>
                                                <em>Select a category</em>
                                            </MenuItem> */}
                                            {categories.map((category) => (
                                                <MenuItem key={category.id} value={category}>
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </FormControl>
                                </Grid>
                                {<Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="subcategory-label">Subcategory</InputLabel>
                                        <CustomSelect
                                            labelId="subcategory-label"
                                            value={selectedSubcategory}
                                            onChange={handleSubcategoryChange}
                                            displayEmpty
                                            label="Subcategory"
                                            disabled={!filteredSubcategories} // Disable if no category is selected
                                        >
                                            {/* <MenuItem value="" disabled>
                                                <em>Select a subcategory</em>
                                            </MenuItem> */}
                                            {selectedCategory && selectedCategory.subcategories?.map((subcategory) => (
                                                <MenuItem key={subcategory.id} value={subcategory.id}>
                                                    {subcategory.name}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </FormControl>
                                </Grid>}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="brand-label">Brand</InputLabel>
                                        <CustomSelect
                                            labelId="brand-label"
                                            value={product.vendorId}
                                            name="vendorId"
                                            onChange={handleChange}
                                            displayEmpty
                                            label="Brand"
                                            disabled={!selectedSubcategory} // Disable if no subcategory is selected
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Select a Brand</em>
                                            </MenuItem>
                                            {filteredVendors.map((vendor) => (
                                                <MenuItem key={vendor.id} value={vendor.id}>
                                                    {vendor.name}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        label="Manufacturer"
                                        name="manufacturer"
                                        value={product.manufacturer}
                                        onChange={handleChange}
                                        placeholder='e.g. Samsung India Electronics Pvt. Ltd.'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        fullWidth
                                        label="Vendor"
                                        name="vendor"
                                        value={product?.vendor}
                                        onChange={handleChange}
                                        placeholder='e.g Samsung India Electronics Pvt. Ltd.'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="country-label">Country of Origin</InputLabel>
                                        <CustomSelect
                                            labelId="country-label"
                                            value={product.countryOfOrigin}
                                            name="countryOfOrigin"
                                            onChange={handleChange}
                                            displayEmpty
                                            label="Country of Origin"
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Select a Country</em>
                                            </MenuItem>
                                            {countryOfOrigin.map((country) => (
                                                <MenuItem key={country.id} value={country.name}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomTextField
                                        fullWidth
                                        label="Price"
                                        name="price"
                                        type='number'
                                        step="0.01"
                                        value={product.price}
                                        onChange={handleChange}
                                        required
                                        placeholder='e.g. 1299.99'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="product-category-label">Product Category</InputLabel>
                                        <CustomSelect
                                            labelId="product-category-label"
                                            name="productCategoryId"
                                            value={product.productCategoryId}
                                            onChange={handleChange}
                                            displayEmpty
                                            label="Product Category"
                                            disabled={!selectedSubcategory} // Disable if no subcategory is selected
                                        >
                                            <MenuItem value="" disabled>
                                                <em>Select a product category</em>
                                            </MenuItem>
                                            {filteredProductCategories.map((category) => (
                                                <MenuItem key={category.id} value={category.id}>
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomTextField
                                        fullWidth
                                        label="Discounted Price"
                                        name="discountedPrice"
                                        type='number'
                                        step="0.01"
                                        value={product.discountedPrice}
                                        onChange={handleChange}
                                        required
                                        placeholder='e.g. 450'
                                    />
                                </Grid>
                                {/* <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="raised-button-file"
                                            type="file"
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="raised-button-file">
                                            <Button variant="contained" component="span">
                                                Upload Image
                                            </Button>
                                        </label>
                                    </Grid>
                                    {imagePreviewUrl && (
                                        <Grid item xs={12}>
                                            <img src={imagePreviewUrl} alt="Image Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                                        </Grid>
                                    )}
                                </Grid> */}
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="tertiary"
                                        fullWidth
                                    >
                                        <Typography textTransform={'none'} color={'white'} variant="button" component="div">
                                            Add Product
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={open}
                        autoHideDuration={10000}
                        onClose={handleClose}
                        message={createProductStatus ? 'Product added successfully' : 'Failed to add product'}
                    />
                </Card>
            </Box>
        </Container>
    );
};

export default AddProduct;