import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  useMediaQuery,
  Box,
  Badge,
  MenuItem,
  Menu,
  Fade,
  Divider,
} from '@mui/material';

import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Person2Outlined from '@mui/icons-material/Person2Outlined';
import { useNavigate } from 'react-router-dom';
import CategoryNavbar from './CategoryNavbar';
import ShoppingCartOutlined from '@mui/icons-material/ShoppingCartOutlined';
import { useSelector } from 'react-redux';
import Search from './Product/Search';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined';
import { useDispatch } from 'react-redux';
import { getUserDetails, logoutUser } from '../features/users/usersSlice';
import Cookies from 'js-cookie';
import CategoryNavBarMobile from './CategoryNavBarMobile';
import { fetchCategories } from '../features/categories/categoriesSlice';
import { categories } from '../mockData';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.users.user);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const onWishListClick = () => {
    handleClose();
    navigate('/wishlist');
  };

  const logout = () => {
    // Perform logout logic here
    dispatch(logoutUser());
    // navigate('/login');
    handleClose();
  };

  const cart = useSelector((state) => state.carts.cart);

  console.log('userInfo', userInfo);

  useEffect(()=> {
      if(userInfo?.authenticated && userInfo?.token) {
        dispatch(getUserDetails())
      }
  }, [userInfo, dispatch])

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const onClickListItem = (page) => {
    handleClose();
    toggleDrawer(false);
    navigate(page);
  }

  const onDrawerItemClick = (page) => {
    toggleDrawer(false);
    navigate(page);
  }

  return (
    <>
      <AppBar color='secondary' position="static" elevation={0}>
        <Toolbar color='secondary' sx={{ justifyContent: 'space-between', alignContent: 'space-around' }}>
          {isMobile ? (
            <>
              {/* <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton> */}
              <IconButton disableRipple color='inherit' onClick={() => navigate('/')} >
                <Box component='img' sx={{ width: '40px', height: '20px', padding: 0 }} src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" />
              </IconButton>
              {/* <Search /> */}
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0 }}>
                <IconButton disableRipple color='inherit' onClick={handleClick}>
                  <Person2Outlined />
                </IconButton>
                <IconButton sx={{ padding: '6px' }} disableRipple color='inherit' onClick={() => navigate('/wishlist')}>
                  <FavoriteBorderOutlined fontSize='small' />
                </IconButton>
                <IconButton sx={{ padding: '6px' }} disableRipple color='inherit' onClick={() => navigate('cart')}>
                  <ShoppingCartOutlined fontSize='small' />
                </IconButton>
              </Box>
            </>
          ) : (
            <>
              <IconButton disableRipple color='inherit' onClick={() => navigate('/')} >
                <Box
                  component='img' sx={{ width: '85px', height: '40px', padding: 0 }}
                  src={process.env.PUBLIC_URL + '/logo.png'}
                  alt="Logo"
                />
              </IconButton>
              <Search />
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <IconButton
                  sx={{ flexGrow: 1, flexDirection: 'column', textTransform: 'none', alignItems: 'center' }}
                  color="inherit"
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  onMouseEnter={handleClick}
                >
                  <Person2Outlined />
                  <Typography align='center' variant='body2' color="inherit">
                    {userInfo?.authenticated && userInfo?.token ? `${userInfo?.firstName}` : 'Profile'}
                  </Typography>
                </IconButton>
                <IconButton
                  disableRipple
                  sx={{ flexDirection: 'column', textTransform: 'none', alignItems: 'center' }}
                  color="inherit" onClick={() => onClickListItem('/wishlist')}>
                  <FavoriteBorderOutlined />
                  <Typography align='center' variant='body2' color="inherit">
                    Wishlist
                  </Typography>
                </IconButton>
                <Button startIcon={<Badge badgeContent={cart?.length ?? 0} color='secondary'><ShoppingCartOutlined color='secondary' /></Badge>} color='tertiary' variant='contained' sx={{ backgroundColor: 'green', textTransform: 'none' }} onClick={() => onClickListItem('cart')}>
                  <Typography align='center' variant='subtitle2' color="white">
                    My Cart
                  </Typography>
                </Button>
              </Box>
            </>
          )}
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            sx={{
              marginTop: '8px',
            }}
          >
            <MenuItem disableRipple sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='h6' sx={{ alignSelf: 'flex-start' }}>
                Welcome
              </Typography>
              {userInfo?.authenticated && userInfo?.token ?
                <Typography variant='caption' alignSelf='flex-start'>{`${userInfo?.firstName} ${userInfo?.lastName}`}</Typography> :
                <>
                  {!isMobile && <Typography variant='caption'>
                    To access your account, and manage your orders
                  </Typography>}
                  <Button sx={{ alignSelf: 'flex-start', marginTop: '8px' }} variant='outlined' color='tertiary' onClick={() => onClickListItem('/otplogin')}>
                    Login / Signup
                  </Button>
                </>
              }
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => onClickListItem('/profile')}>
              <ListItemIcon>
                <Person2Outlined fontSize="small" />
              </ListItemIcon>
              My Profile
            </MenuItem>
            <MenuItem onClick={onWishListClick}>
              <ListItemIcon>
                <FavoriteBorderOutlined fontSize="small" />
              </ListItemIcon>
              My Wishlist
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            {userInfo?.authenticated && userInfo?.token && <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>}
          </Menu>
        </Toolbar>
      </AppBar>
      {!isMobile ? <CategoryNavbar categories={categories} />: <Search/>}
      {isMobile && <CategoryNavBarMobile categories={categories} />}
      {/* {isMobile && (
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <List>
            <ListItemButton onClick={() => onDrawerItemClick('/home')}>
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton onClick={() => onDrawerItemClick('/wishlist')}>
              <ListItemIcon>
                <FavoriteBorderOutlined />
              </ListItemIcon>
              <ListItemText primary="Wishlist" />
            </ListItemButton>
            <ListItemButton onClick={() => onDrawerItemClick('cart')}>
              <ListItemIcon>
                <Badge badgeContent={cart?.length ?? 0} color='primary'><ShoppingCartOutlined /></Badge>
              </ListItemIcon>
              <ListItemText primary="Cart" />
            </ListItemButton>

            {userInfo?.authenticated ? <ListItemButton onClick={logout}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton> :
              <ListItemButton onClick={() => onDrawerItemClick('/otplogin')}>
                <ListItemIcon>
                  <Person2Outlined />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItemButton>}
          </List>
        </Drawer>
      )} */}
    </>
  );
};

export default Navbar;
