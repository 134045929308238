import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Card, CardActionArea, CardMedia, CardContent, CardActions, Button, Box, IconButton, Skeleton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { createCart, decrementCart, updateCart } from '../../features/carts/cartSlice';
import { addToWishlist, removeFromWishlist } from '../../features/wishlists/wishlistSlice';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteBorder from '@mui/icons-material/FavoriteSharp';
import { useNavigate } from 'react-router-dom';

const Products = ({ filteredProducts, title, nodataComponent, gridProps }) => {
    const cart = useSelector(state => state.carts.cart);
    const wishlist = useSelector(state => state.wishlists.wishlist);
    const wishlistIds = wishlist.map(item => item.id);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const productStatus = useSelector(state => state.products.status);

    const handleCardClick = (productId) => {
        navigate(`/product/${productId}`);
    };

    const handleAddToCart = (product) => {
        dispatch(createCart(product));
    };

    const handleIncrement = (product) => {
        dispatch(updateCart(product));
    };

    const handleAddToWishlist = (product) => {
        // check if the product already exists in the wishlist
        if (wishlistIds.includes(product.id)) {
            dispatch(removeFromWishlist(product.id));
            return;
        }
        dispatch(addToWishlist(product));
    };

    const handleDecrement = (product) => {
        const index = cart.findIndex(item => item.id === product.id);
        if (index !== -1) {
            const newCart = [...cart];
            newCart.splice(index, 1);
            dispatch(decrementCart(newCart));
        }
    };

    if (productStatus === 'loading') {
        return (
            <div>
                <Skeleton variant="rect" width={210} height={118} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </div>
        );
    }

    return (
        <>
            {filteredProducts.length > 0 && <Typography variant='h5'>{title}</Typography>}
            <Grid container spacing={2} sx={{ marginTop: '56px' }}>
                {filteredProducts.length > 0 ? filteredProducts.map((product) => (
                    <Grid item {...gridProps} key={product.id}>
                        <Card elevation={0} sx={{
                            width: '256px', height: '370px', border: '1px solid #D5D5D5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', transition: 'box-shadow 0.10s ease-in-out, transform 0.3s ease-in-out',
                            '&:hover': {
                                boxShadow: 6, // Elevation level on hover
                                transform: 'scale(1.02)', // Scale effect on hover
                                transition: 'box-shadow 0.10s ease-in-out, transform 0.3s ease-in-out',
                            },
                        }}>
                            <CardActionArea onClick={() => handleCardClick(product.id)}>
                                <CardMedia
                                    component="img"
                                    alt={product.name}
                                    height="200"
                                    width={'100%'}
                                    image={product.imageUrl || product.primaryImage}
                                    title={product.name}
                                    loading='lazy'
                                    sx={{ objectFit: 'contain' }}
                                />
                                <CardContent sx={{ padding: '4px 0px 0px 4px' }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        <Typography variant='body1' component="p">
                                            {product.name}
                                        </Typography>
                                        <Typography variant="caption" component="p">
                                            {product?.description}
                                        </Typography>
                                        <Typography variant="caption" component="p" sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <span style={{ textDecoration: 'line-through', color: 'grey' }}>
                                                ₹{product?.price}
                                            </span>
                                            <span style={{ color: 'red' }}>
                                                ₹{product?.discountedPrice || (product?.price - (product?.price * (product?.discount / 100))).toFixed(2)}
                                            </span>
                                            <span style={{ color: 'green' }}>
                                                ({product?.discount}% off)
                                            </span>
                                        </Typography>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#F5F5DC', height: '50px' }}>
                                {cart?.filter(item => item.id === product.id)?.length === 0 ? (<Button size="small" variant='outlined' color="tertiary" sx={{ textTransform: 'none' }} onClick={() => handleAddToCart(product)}>
                                    <Typography variant="caption" component="span">
                                        Add to Cart
                                    </Typography>
                                </Button>) :
                                    (<Box sx={{ backgroundColor: 'green', borderRadius: '4px' }}>
                                        <IconButton size="medium" color="primary" onClick={() => handleDecrement(product)}>
                                            <RemoveIcon sx={{ width: '16px', height: '16px' }} />
                                        </IconButton>
                                        <Typography variant='body2' color={'white'} component="span">
                                            {cart?.filter(item => item.id === product.id)?.length}
                                        </Typography>
                                        <IconButton size="medium" color="primary" onClick={() => handleIncrement(product)}>
                                            <AddIcon sx={{ width: '16px', height: '16px' }} />
                                        </IconButton>
                                    </Box>)}
                                <Button startIcon={wishlistIds.includes(product.id) ? <FavoriteBorder /> : <FavoriteBorderIcon />} size="small" variant='outlined' color="tertiary" sx={{ textTransform: 'none' }} onClick={() => handleAddToWishlist(product)}>
                                    <Typography variant="caption" component="span">
                                        Wishlist
                                    </Typography>
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                )) : nodataComponent}
            </Grid>
        </>
    );
};

export default Products;