import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { setUserToken } from './features/users/usersSlice';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const theme = createTheme({
  palette: {
    primary: {
      // gray
      main: '#9e9e9e',
      dark: '#000000',
      light: '#ffffff',
    },
    secondary: {
      // light grey
      main: '#f5f5f5',
      // main: '#800040',
      dark: '#000000',
      light: '#ffffff',
    },
    // secondary: {
    //   // mid grey
    //   main: '#9e9e9e',
    // },
    tertiary: {
      // green
      main: '#4caf50',
      dark: '#097969',
      // light green
      light: 'F0FFFF',

    },
  },
  typography: {
    h4: {
      fontStyle: 'bold',
      fontFamily: 'Assistant, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif',
    },
    h6: {
      fontStyle: 'bold',
      fontSize: '1rem',
    },
    // caption: {
    //   fontSize: '0.4rem',
    // },
    fontFamily: 'Assistant, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif',
    color: 'white', // Set the text color to white for better contrast
  },
});

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    const token = Cookies.get('Authorization');
    if (token) {
      dispatch(setUserToken({ token, authenticated: true }));
    } else {
      dispatch(setUserToken({ token: null, authenticated: false }));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;