import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from '../../axiosConfig';

const initialState = {
    vendors: [],
    status: 'idle',
    error: null,
    createVendorStatus: false,
};

export const fetchVendors = createAsyncThunk('vendors/fetchVendors', async () => {
    const response = await axiosConfig.get('/api/vendors');
    return response.data;
}
);

export const createVendor = createAsyncThunk('vendors/createVendor', async (vendor) => {
    const response = await axiosConfig.post('/api/vendors', vendor);
    return response.data;
}
);

const vendorsSlice = createSlice({
    name: 'vendors',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVendors.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchVendors.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.vendors = action.payload;
            })
            .addCase(fetchVendors.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createVendor.fulfilled, (state, action) => {
                state.createVendorStatus = true;
            }).addCase(createVendor.rejected, (state, action) => {
                state.error = action.error.message;
            }
            );
    },
});

export default vendorsSlice.reducer;