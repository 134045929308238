import { Navigate, createBrowserRouter } from 'react-router-dom';
import About from './components/About';
import Home from './pages/Home';
import ProductsPage from './pages/ProductsPage';
import Registration from './components/User/Registration';
import Profile from './components/User/Profile';
import RootLayout from './RootLayout';
import Wishlist from './components/Wishlist/Wishlist';
import Login from './components/User/Login';
import OtpLogin from './components/User/OtpLogin';
import Cart from './components/Cart/Cart';
import Terms from './components/Policies/Terms';
import Privacy from './components/Policies/Privacy';
import Shipping from './components/Policies/Shipping';
import Cancellation from './components/Policies/Cancellation';
import Refund from './components/Policies/Refund';
import ProductDetails from './components/Product/ProductDetails';
import PrivateRoute from './components/PrivateRoute';
import AddProduct from './pages/Admin';
import Checkout from './components/Checkout';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "home",
        element: <Navigate to="/" replace />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: 'products',
        element: <PrivateRoute>
          <ProductsPage />
        </PrivateRoute>,
        children: [
          {
            path: ':categoryId', // This becomes /products/:categoryId/:subcategoryId
            element: <ProductsPage />,
            children: [
              {
                path: ':subcategoryId', // This becomes /products/:categoryId/:subcategoryId
                element: <ProductsPage />, // Assuming ProductsPage is the component for showing details of a subcategory
                children: [
                  {
                    path: ':vendorId?', // This becomes /products/:categoryId/:subcategoryId/:vendorId
                    element: <ProductsPage />, // Assuming ProductsPage is the component for showing details of a vendor
                    children: [
                      {
                        path: ':productId?', // This becomes /products/:categoryId/:subcategoryId/:vendorId/:productId
                        element: <ProductsPage />, // Assuming ProductsPage is the component for showing details of a product
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'wishlist',
        element: <PrivateRoute>
          <Wishlist />
        </PrivateRoute>
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'otplogin',
        element: <OtpLogin />,
      },
      {
        path: 'register',
        element: <Registration />,
      },
      {
        path: 'cart',
        element: <PrivateRoute>
          <Cart />
          </PrivateRoute>,
      },
      {
        path: 'profile',
        element:
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
      },
      {
        path: 'terms',
        element: <Terms />
      },
      {
        path: 'privacy',
        element: <Privacy />
      },
      {
        path: 'shipping',
        element: <Shipping />
      },
      {
        path: 'cancellation',
        element: <Cancellation />
      },
      {
        path: 'refund',
        element: <Refund />
      },
      {
        path: 'product',
        element: <ProductDetails />,
        children: [
          {
            path: ':productId',
            element: <ProductDetails />,
          },
        ]
      },
      {
        path: 'addProduct',
        element: <AddProduct />,
      },
      {
        path: 'checkout',
        element: <Checkout />,
      }
    ],
  },
]);


export default router;