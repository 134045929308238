export const getFilteredCartItems = (cartItems) => {
    // create a new array with the quantity of each item and unique items
    const filtered = cartItems.reduce((acc, item) => {
        const found = acc.find((x) => x.id === item.id);
        if (found) {
            found.quantity += 1;
        } else {
            acc.push({ ...item, quantity: 1 });
        }
        return acc;
    }, []);
    return filtered;
};

