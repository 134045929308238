import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Box, TextField, Button, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser } from '../../features/users/usersSlice';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function Login() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const userObj = useSelector(state => state.users.user);
    console.log(userObj);
    const onSubmit = data => {
        dispatch(authenticateUser(data));
    };

    useEffect(() => {
        if (userObj && userObj.token && userObj.authenticated) {
            // Redirect to home page
            navigation('/');
        }
    }, [userObj]);

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        {...register('email', { required: 'Email is required', pattern: /^\S+@\S+$/i })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        {...register('password', { required: 'Password is required' })}
                        error={Boolean(errors.password)}
                        helperText={errors.password?.message}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="tertiary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <Typography variant='button' color={'white'}>Login</Typography>
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            {/* Replace # with your forgot password link */}
                            <Typography color='green' variant="body2" component="a" href="#" sx={{ textDecoration: 'none' }}>
                                Forgot password?
                            </Typography>
                        </Grid>
                        <Grid item>
                            {/* Replace # with your sign up link */}
                            <Typography color='green' variant="body2" component="a" href="otplogin" sx={{ textDecoration: 'none' }}>
                                {"Don't have an account? Sign Up"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default Login;