import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from '../../axiosConfig';

const initialState = {
    productCategories: [],
    status: 'idle',
    error: null,
    createProductCategoryStatus: false,
};

export const fetchProductCategories = createAsyncThunk('productCategories/fetchProductCategories', async () => {
    const response = await axiosConfig.get('/api/product-categories');
    return response.data;
}
);

export const createProductCategory = createAsyncThunk('productCategories/createProductCategory', async (productCategory) => {
    const response = await axiosConfig.post('/api/product-categories', productCategory);
    return response.data;
}
);

const productCategoriesSlice = createSlice({
    name: 'productCategories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductCategories.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProductCategories.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.productCategories = action.payload;
            })
            .addCase(fetchProductCategories.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createProductCategory.fulfilled, (state, action) => {
                state.createProductCategoryStatus = true;
            }).addCase(createProductCategory.rejected, (state, action) => {
                state.error = action.error.message;
            }
            );
    },
});

export default productCategoriesSlice.reducer;