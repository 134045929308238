
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { authenticateUser, checkUser, getUserDetails } from '../../features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';

const PhoneNumberVerification = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phonenumberVerified, setPhonenumberVerified] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userObj = useSelector((state) => state.users.user);
    
    useEffect(() => {
        if (userObj && userObj.token && userObj.authenticated) {
            // Store token in a cookie
            dispatch(getUserDetails());
            navigate('/');
        }
    }, [userObj]);

    useEffect(() => {
        // Load the external script
        const script = document.createElement('script');
        script.src = "https://www.phone.email/sign_in_button_v1.js";
        script.async = true;
        document.body.appendChild(script);

        // Define the listener function
        window.phoneEmailListener = function(userObj) {
            const {user_phone_number, user_country_code} = userObj;
            setPhoneNumber(user_phone_number);
            setPhonenumberVerified(true);
        };

        return () => {
            // Cleanup the listener function when the component unmounts
            window.phoneEmailListener = null;
        };
    }, []);

    useEffect(() => {
        if(phonenumberVerified) {
            dispatch(checkUser({ phone: phoneNumber }))
            .then((response) => {
                window.phoneEmailListener = null;
                if (response.payload?.isUserExists === true) {
                     dispatch(authenticateUser({ phone: phoneNumber }));
                } else {
                    navigate('/register')
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, [phonenumberVerified]);

    return (
        <Box mt={4} className="pe_signin_button" data-client-id="14685266978639066039"></Box>
    );
};

export default PhoneNumberVerification;
