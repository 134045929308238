import React, { useEffect } from 'react';
import { Grid, Container, Box, useMediaQuery } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { bottomContanerImages, carouselImages, categories } from '../mockData';
import ShopByCategory from '../components/Product/ShopByCategory';
import { fetchProducts } from '../features/products/productsSlice';
import { useDispatch } from 'react-redux';

const Home = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const containerStyle = isMobile ? { padding: '0px' } : { padding: '0px 60px' };
  const carousalheight = isMobile ? '150px' : '365px';
  const categoriesFromServer = useSelector((state) => state.categories.categories);

  return (
    <>
      <Container maxWidth= 'lg' sx={{ marginTop: '8px' }}>
        <Box sx={{ ...containerStyle }}>
          <Carousel
            autoPlay
            centerMode
            centerSlidePercentage={100}
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            interval={5000}
            showArrows={isMobile ? false : true}
            showIndicators={isMobile ? false : true}
          >
            {carouselImages.map((image, index) => (
              <Box key={index} sx={{ height: carousalheight }}>
                <Box component={'img'} src={image}
                  alt={'top-image-container'}
                  sx={{ width: '100%', height: '100%', objectFit: 'initial' }}
                />
              </Box>
            ))}
          </Carousel>
        </Box>
        <Grid container spacing={4} style={{ paddingTop: '16px' }}>
          <ShopByCategory categories={categories} />
        </Grid>
        <Grid container spacing={3} p={8} pt={2} direction='column'>
          {bottomContanerImages.map((image, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <img src={image} alt={`Bottom Container ${index}`} style={{ width: '100%', height: '40vh' }} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Home;
