// src/pages/Terms.js
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Terms = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ padding: '32px', backgroundColor: 'white' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.dark' }}>
          Terms & Conditions
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6 }}>
          <strong>Introduction</strong><br />
          Welcome to OrderMyGift. By accessing or using our website, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use our services.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Use of Our Services</strong><br />
          You agree to use our services only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the services. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our services.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Account Registration</strong><br />
          To access certain features of our website, you may be required to register an account. You agree to provide accurate and complete information during the registration process and to keep your account information up to date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Orders and Payments</strong><br />
          All orders placed through our website are subject to acceptance and availability. We reserve the right to refuse or cancel any order for any reason. Prices for our products are subject to change without notice. You agree to pay all charges associated with your order, including any applicable taxes and shipping fees.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Shipping and Delivery</strong><br />
          We aim to deliver your order within the estimated delivery time provided at checkout. However, delivery times are not guaranteed and may be affected by factors beyond our control. We are not responsible for any delays in delivery.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Returns and Refunds</strong><br />
          If you are not satisfied with your purchase, you may return the product within 30 days of receipt for a refund or exchange, subject to our return policy. The product must be in its original condition and packaging. Shipping costs for returns are the responsibility of the customer.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Intellectual Property</strong><br />
          All content on our website, including text, graphics, logos, images, and software, is the property of OrderMyGift or its content suppliers and is protected by intellectual property laws. You may not use, reproduce, or distribute any content from our website without our prior written permission.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Limitation of Liability</strong><br />
          To the fullest extent permitted by law, OrderMyGift shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our services. Our total liability to you for any claim arising out of or in connection with these terms shall not exceed the amount paid by you for the products in question.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Governing Law</strong><br />
          These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which OrderMyGift operates. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of that jurisdiction.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Changes to These Terms</strong><br />
          We reserve the right to update or modify these terms and conditions at any time without prior notice. Your continued use of our services after any changes indicates your acceptance of the new terms.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6, mt: 2 }}>
          <strong>Contact Us</strong><br />
          If you have any questions about these terms and conditions, please contact us at  Support@procurehelpdesk.com.
        </Typography>
      </Paper>
    </Container>
  );
};

export default Terms;