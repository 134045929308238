// get from cookies
import { House, LocalShipping, Payment, Person } from '@mui/icons-material';
import Cookies from 'js-cookie';

const baseUrl = Cookies.get('IMAGE_BASE_URL') || 'https://ordermygift.org:30443';

export const categories = [
    { id: 1, name: 'Home & Kitchen', subcategories: [{ id: 1, name: 'Kitchen Appliances', imageUrl: process.env.PUBLIC_URL + 'kitchen-appliances.png' }, { id: 2, name: 'Home Appliances', imageUrl: process.env.PUBLIC_URL + 'home-appliances.png' }, { id: 3, name: 'Kitchenware', imageUrl: process.env.PUBLIC_URL + 'kitchenware.png' }, { id: 4, name: 'Storage & Containers', imageUrl: process.env.PUBLIC_URL + 'storage.png' }, { id: 5, name: 'Serveware', imageUrl: process.env.PUBLIC_URL + 'serveware.png' }] },
    { id: 2, name: 'Lifestyle', subcategories: [{ id: 1, name: 'Automobile & Accessories', imageUrl: process.env.PUBLIC_URL + 'automobile.png' }, { id: 2, name: 'Personal Care', imageUrl: process.env.PUBLIC_URL + 'personal-care.png' }, { id: 3, name: 'Heath & Wellness', imageUrl: process.env.PUBLIC_URL + 'health-wellness.png' }, { id: 4, name: 'Luggage & Backpacks', imageUrl: process.env.PUBLIC_URL + 'luggage.png' }, { id: 5, name: 'Leather Accessories', imageUrl: process.env.PUBLIC_URL + 'leather.png' }] },
    { id: 3, name: 'Electronics', subcategories: [{ id: 1, name: 'Audio', imageUrl: process.env.PUBLIC_URL + 'audio.png' }, { id: 2, name: 'Video Display', imageUrl: process.env.PUBLIC_URL + 'video-display.png' }, { id: 3, name: 'Computers & Accessories', imageUrl: process.env.PUBLIC_URL + 'computers.png' }, { id: 4, name: 'Smart Watch & Clocks', imageUrl: process.env.PUBLIC_URL + 'smart-watch.png' }, { id: 5, name: 'Smart Robots', imageUrl: process.env.PUBLIC_URL + 'smart-robots.png' }] },
    { id: 4, name: 'Home Furnishing', subcategories: [{ id: 1, name: 'Bed Linen & Bedspreads', imageUrl: process.env.PUBLIC_URL + 'bed-linen.png' }, { id: 2, name: 'Blankets', imageUrl: process.env.PUBLIC_URL + 'blankets.png' }, { id: 3, name: 'Furniture', imageUrl: process.env.PUBLIC_URL + 'furniture.png' }, { id: 4, name: 'Home Décor', imageUrl: process.env.PUBLIC_URL + 'home-decor.png' }, { id: 5, name: 'Fragrances & Essential Oils', imageUrl: process.env.PUBLIC_URL + 'fragrances.png' }] },
    { id: 5, name: 'Dhanteras Special', subcategories: [{ id: 1, name: 'Gold & Silver Coins', imageUrl: process.env.PUBLIC_URL + 'silver.png' }, { id: 2, name: 'Utensils', imageUrl: process.env.PUBLIC_URL + 'utensils.png' }] },
    { id: 6, name: 'Diwali Special', subcategories: [{ id: 1, name: 'Sweets', imageUrl: process.env.PUBLIC_URL + 'sweets.png' }, { id: 2, name: 'Puja Kits', imageUrl: process.env.PUBLIC_URL + 'puja-kits.png' }, { id: 3, name: 'Idols', imageUrl: process.env.PUBLIC_URL + 'idols.png' },] },
];

export const productTypes = [
    'New',
    'Open Box'
];

export const populerBanks = [
    { id: 1, name: 'HDFC Bank', logo: `${process.env.PUBLIC_URL}/hdfc-bank-logo.svg` },
    { id: 2, name: 'ICICI Bank', logo: `${process.env.PUBLIC_URL}/icici-bank-logo.svg` },
    { id: 3, name: 'Axis Bank', logo: `${process.env.PUBLIC_URL}/axis-bank-logo.svg` },
    // { id: 4, name: 'Kotak Mahindra Bank', logo: `${process.env.PUBLIC_URL}/kotak-bank-logo.svg` },
    { id: 4, name: 'State Bank of India', logo: `${process.env.PUBLIC_URL}/sbi-bank-logo.svg` },
];

export const otherBanks = [
    { id: 6, name: 'Bank of Baroda' },
    { id: 7, name: 'Canara Bank' },
    { id: 8, name: 'Punjab National Bank' },
    { id: 9, name: 'Union Bank of India' },
    { id: 10, name: 'Bank of India' },
    { id: 11, name: 'Kotak Mahindra Bank' },
];

export const checkoutSteps = [
    { id: 0, name: 'Sign In', icon: Person  },
    { id: 1, name: 'Shipping Address', icon: House },
    { id: 2, name: 'Payment', icon: Payment },
];

export const imageFormats = [
    'jpeg',
    'jpg',
    'png',
    'gif',
    'bmp',
    'svg',
    'webp'
];

export const carouselImages = [
    `${baseUrl}/carousel-1.jpg`,
    `${baseUrl}/carousel-2.jpg`,
    `${baseUrl}/carousel-3.jpg`,
    `${baseUrl}/carousel-4.jpg`,
    `${baseUrl}/carousel-5.jpg`,
    `${baseUrl}/carousel-6.jpg`,
    `${baseUrl}/carousel-7.jpg`,
    `${baseUrl}/carousel-8.jpg`,
    `${baseUrl}/carousel-9.jpg`,
    `${baseUrl}/carousel-10.jpg`,
    `${baseUrl}/carousel-11.jpg`,
];

export const countryOfOrigin = [
    { id: 1, name: 'India' },
    { id: 2, name: 'China' },
    { id: 3, name: 'USA' },
    { id: 4, name: 'Germany' },
    { id: 5, name: 'Italy' },
    { id: 6, name: 'France' },
    { id: 7, name: 'Japan' },
    { id: 8, name: 'UK' },
    { id: 9, name: 'Australia' },
    { id: 10, name: 'Canada' },
    { id: 11, name: 'Spain' },
    { id: 12, name: 'Brazil' },
    { id: 13, name: 'Russia' },
    { id: 14, name: 'South Africa' },
    { id: 15, name: 'South Korea' },
    { id: 16, name: 'Mexico' },
    { id: 17, name: 'Netherlands' },
    { id: 18, name: 'Sweden' },
    { id: 19, name: 'Switzerland' },
    { id: 20, name: 'UAE' },
    { id: 21, name: 'Saudi Arabia' },
    { id: 22, name: 'Qatar' },
    { id: 23, name: 'Kuwait' },
    { id: 24, name: 'Oman' },
    { id: 25, name: 'Bahrain' },
    { id: 26, name: 'New Zealand' },
    { id: 27, name: 'Singapore' },
    { id: 28, name: 'Malaysia' },
    { id: 29, name: 'Thailand' },
    { id: 30, name: 'Indonesia' },
    { id: 31, name: 'Vietnam' },
    { id: 32, name: 'Philippines' },
    { id: 33, name: 'Sri Lanka' },
    { id: 34, name: 'Bangladesh' },
    { id: 35, name: 'Pakistan' },
    { id: 36, name: 'Nepal' },
    { id: 37, name: 'Bhutan' },
    { id: 38, name: 'Maldives' },
    { id: 39, name: 'Afghanistan' },
    { id: 40, name: 'Iran' },
    { id: 41, name: 'Iraq' },
    { id: 42, name: 'Syria' },
    { id: 43, name: 'Turkey' },
    { id: 44, name: 'Greece' },
    { id: 45, name: 'Egypt' },
];

export const bottomContanerImages = [
    `${baseUrl}/bottom-container1.jpg`,
    `${baseUrl}/bottom-container2.jpg`,
    `${baseUrl}/bottom-container3.jpg`,
    
  ];

// export const vendors = [
//     { id: 1, name: 'Shagun', categoryId: 6, subcategoryId: 1 },
//     { id: 2, name: 'Haldirams', categoryId: 6, subcategoryId: 1 },
//     { id: 3, name: 'Mithaas', categoryId: 6, subcategoryId: 1 },
//     { id: 4, name: 'Hira Sweets', categoryId: 6, subcategoryId: 1 },

//     { id: 19, name: 'Prestige', categoryId: 1, subcategoryId: 3 },
//     { id: 20, name: 'Bajaj', categoryId: 1, subcategoryId: 3 },
//     { id: 21, name: 'Philips', categoryId: 1, subcategoryId: 3 },
//     { id: 22, name: 'Hawkins', categoryId: 1, subcategoryId: 3 },
//     { id: 23, name: 'Pigeon', categoryId: 1, subcategoryId: 3 },
//     { id: 24, name: 'Wonderchef', categoryId: 1, subcategoryId: 3 },
//     // home appliances vendors
//     { id: 25, name: 'Philips', categoryId: 1, subcategoryId: 2 },
//     { id: 26, name: 'Bajaj', categoryId: 1, subcategoryId: 2 },
//     { id: 27, name: 'Havells', categoryId: 1, subcategoryId: 2 },
//     { id: 28, name: 'Usha', categoryId: 1, subcategoryId: 2 },
//     { id: 29, name: 'Morphy Richards', categoryId: 1, subcategoryId: 2 },
//     { id: 30, name: 'Crompton', categoryId: 1, subcategoryId: 2 },
   
//     // bags vendors
//     { id: 31, name: 'American Tourister', categoryId: 2, subcategoryId: 4 },
//     { id: 32, name: 'Skybags', categoryId: 2, subcategoryId: 4 },
//     { id: 33, name: 'VIP', categoryId: 2, subcategoryId: 4 },
//     { id: 34, name: 'Wildcraft', categoryId: 2, subcategoryId: 4 },
//     { id: 35, name: 'Puma', categoryId: 2, subcategoryId: 4 },
//     { id: 36, name: 'Adidas', categoryId: 2, subcategoryId: 4 },
//     // silver coin vendors
//     { id: 37, name: 'Shagun', categoryId: 5, subcategoryId: 1 },
//     { id: 38, name: 'MMTC', categoryId: 5, subcategoryId: 1 },
//     // puja kits vendors
//     { id: 39, name: 'Shagun', categoryId: 6, subcategoryId: 2 },
//     { id: 40, name: 'MMTC', categoryId: 6, subcategoryId: 2 },
//     // stoarage container vendors
//     { id: 43, name: 'Tupperware', categoryId: 1, subcategoryId: 4 },
//     { id: 44, name: 'Cello', categoryId: 1, subcategoryId: 4 },
//     { id: 45, name: 'Signoraware', categoryId: 1, subcategoryId: 4 },
//     { id: 46, name: 'Princeware', categoryId: 1, subcategoryId: 4 },
//     { id: 47, name: 'All Time', categoryId: 1, subcategoryId: 4 },
//     { id: 48, name: 'Solimo', categoryId: 1, subcategoryId: 4 },

//     // serve ware vendors
//     { id: 49, name: 'Corelle', categoryId: 1, subcategoryId: 5 },
//     { id: 50, name: 'Borosil', categoryId: 1, subcategoryId: 5 },
//     { id: 51, name: 'LaOpala', categoryId: 1, subcategoryId: 5 },
//     { id: 52, name: 'Cello', categoryId: 1, subcategoryId: 5 },
//     { id: 53, name: 'Treo', categoryId: 1, subcategoryId: 5 },
//     { id: 54, name: 'Larah', categoryId: 1, subcategoryId: 5 },
//     // personal care vendors
//     { id: 55, name: 'Philips', categoryId: 2, subcategoryId: 2 },
//     { id: 56, name: 'Braun', categoryId: 2, subcategoryId: 2 },
//     { id: 57, name: 'Panasonic', categoryId: 2, subcategoryId: 2 },
//     { id: 58, name: 'Vega', categoryId: 2, subcategoryId: 2 },
//     { id: 59, name: 'Syska', categoryId: 2, subcategoryId: 2 },
//     { id: 60, name: 'Havells', categoryId: 2, subcategoryId: 2 },
//     // automobile accessories vendors
//     { id: 61, name: 'Bosch', categoryId: 2, subcategoryId: 1 },
//     { id: 62, name: '3M', categoryId: 2, subcategoryId: 1 },
//     { id: 63, name: 'Vega', categoryId: 2, subcategoryId: 1 },
//     { id: 64, name: 'Studds', categoryId: 2, subcategoryId: 1 },
//     { id: 65, name: 'Steelbird', categoryId: 2, subcategoryId: 1 },
//     { id: 66, name: 'MRF', categoryId: 2, subcategoryId: 1 },
//     // health and wellness vendors
//     { id: 67, name: 'Dabur', categoryId: 2, subcategoryId: 3 },
//     { id: 68, name: 'Patanjali', categoryId: 2, subcategoryId: 3 },
//     { id: 69, name: 'Himalaya', categoryId: 2, subcategoryId: 3 },
//     { id: 70, name: 'Amway', categoryId: 2, subcategoryId: 3 },
//     { id: 71, name: 'Baidyanath', categoryId: 2, subcategoryId: 3 },
//     { id: 72, name: 'Zandu', categoryId: 2, subcategoryId: 3 },
//     // leather accessories vendors
//     { id: 73, name: 'Hidesign', categoryId: 2, subcategoryId: 5 },
//     { id: 74, name: 'Da Milano', categoryId: 2, subcategoryId: 5 },
//     { id: 75, name: 'Baggit', categoryId: 2, subcategoryId: 5 },
//     { id: 76, name: 'Caprese', categoryId: 2, subcategoryId: 5 },
//     { id: 77, name: 'Lavie', categoryId: 2, subcategoryId: 5 },
//     { id: 78, name: 'Wildcraft', categoryId: 2, subcategoryId: 5 },
//     // audio vendors
//     { id: 79, name: 'Sony', categoryId: 3, subcategoryId: 1 },
//     { id: 80, name: 'JBL', categoryId: 3, subcategoryId: 1 },
//     { id: 81, name: 'Boat', categoryId: 3, subcategoryId: 1 },
//     { id: 82, name: 'Bose', categoryId: 3, subcategoryId: 1 },
//     { id: 83, name: 'Philips', categoryId: 3, subcategoryId: 1 },
//     { id: 84, name: 'Sennheiser', categoryId: 3, subcategoryId: 1 },
//     // video display vendors
//     { id: 85, name: 'Sony', categoryId: 3, subcategoryId: 2 },
//     { id: 86, name: 'LG', categoryId: 3, subcategoryId: 2 },
//     { id: 87, name: 'Samsung', categoryId: 3, subcategoryId: 2 },
//     { id: 88, name: 'OnePlus', categoryId: 3, subcategoryId: 2 },
//     { id: 89, name: 'Mi', categoryId: 3, subcategoryId: 2 },
//     { id: 90, name: 'Realme', categoryId: 3, subcategoryId: 2 },
//     // computer accessories vendors
//     { id: 91, name: 'Dell', categoryId: 3, subcategoryId: 3 },
//     { id: 92, name: 'HP', categoryId: 3, subcategoryId: 3 },
//     { id: 93, name: 'Apple', categoryId: 3, subcategoryId: 3 },
//     { id: 94, name: 'Lenovo', categoryId: 3, subcategoryId: 3 },
//     { id: 95, name: 'Asus', categoryId: 3, subcategoryId: 3 },
//     { id: 96, name: 'Acer', categoryId: 3, subcategoryId: 3 },
//     // smart watch vendors
//     { id: 97, name: 'Apple', categoryId: 3, subcategoryId: 4 },
//     { id: 98, name: 'Samsung', categoryId: 3, subcategoryId: 4 },
//     // smart robot vendors
//     { id: 103, name: 'iRobot', categoryId: 3, subcategoryId: 5 },
//     { id: 104, name: 'Milagrow', categoryId: 3, subcategoryId: 5 },

//     // bed linen vendors
//     { id: 109, name: 'Bombay Dyeing', categoryId: 4, subcategoryId: 1 },
//     { id: 110, name: 'Spaces', categoryId: 4, subcategoryId: 1 },
//     { id: 111, name: 'Portico', categoryId: 4, subcategoryId: 1 },
//     { id: 112, name: 'Raymond Home', categoryId: 4, subcategoryId: 1 },

//     // blanket vendors
//     { id: 115, name: 'Bombay Dyeing', categoryId: 4, subcategoryId: 2 },
//     { id: 116, name: 'Spaces', categoryId: 4, subcategoryId: 2 },
//     { id: 117, name: 'Portico', categoryId: 4, subcategoryId: 2 },
//     { id: 118, name: 'Raymond Home', categoryId: 4, subcategoryId: 2 },
//     // furniture vendors
//     { id: 123, name: 'Godrej Interio', categoryId: 4, subcategoryId: 3 },
//     { id: 124, name: 'Nilkamal', categoryId: 4, subcategoryId: 3 },
//     { id: 125, name: 'Durian', categoryId: 4, subcategoryId: 3 },
//     // home decor vendors
//     { id: 127, name: 'FabIndia', categoryId: 4, subcategoryId: 4 },
//     { id: 131, name: 'Urban Ladder', categoryId: 4, subcategoryId: 4 },
//     { id: 132, name: 'Home Centre', categoryId: 4, subcategoryId: 4 },
//     // fragrances & essential oils vendors
//     { id: 133, name: 'Forest Essentials', categoryId: 4, subcategoryId: 5 },
//     { id: 134, name: 'Kama Ayurveda', categoryId: 4, subcategoryId: 5 },
//     { id: 135, name: 'Nykaa', categoryId: 4, subcategoryId: 5 },
//     { id: 136, name: 'Soulflower', categoryId: 4, subcategoryId: 5 },
//     { id: 137, name: 'The Body Shop', categoryId: 4, subcategoryId: 5 },
//     // utensils vendors
//     { id: 139, name: 'Prestige', categoryId: 5, subcategoryId: 2 },
//     { id: 140, name: 'Hawkins', categoryId: 5, subcategoryId: 2 },
//     { id: 141, name: 'Pigeon', categoryId: 5, subcategoryId: 2 },
//     { id: 142, name: 'Vinod', categoryId: 5, subcategoryId: 2 },
// ];

// export const productCategories = [
//     // appliances categories  - Air Fryer
//     //  - Electric Kettle
//     //  - Mixer/Grinder
//     //  - Hand Chooper
//     //  - Electric Chopper
//     //  - Hand blender
//     //  - Gyser
//     //  - Fans
//     //  - Vaccum Cleaner
//     //  - Iron 
//     //  - Sandwitch maker
//     //  - OTG
//     //  - Microwave
//     //  - Cooler
//     //  - Heater/Oil
//     //  - Dish Washer
//     //  - Washing Machine
//     { id: 1, name: ' Mixer/Grinder', categoryId: 1, subcategoryId: 1 },
//     { id: 2, name: ' Air Fryer', categoryId: 1, subcategoryId: 1 },
//     { id: 3, name: ' Electric Kettle', categoryId: 1, subcategoryId: 1 },
//     { id: 4, name: ' Hand Chopper', categoryId: 1, subcategoryId: 1 },
//     { id: 5, name: ' Electric Chopper', categoryId: 1, subcategoryId: 1 },
//     { id: 6, name: ' Hand blender', categoryId: 1, subcategoryId: 1 },
//     { id: 7, name: ' Iron', categoryId: 1, subcategoryId: 2 },
//     { id: 8, name: 'Refrigerator', categoryId: 1, subcategoryId: 2 },
//     { id: 9, name: ' Washing Machine', categoryId: 1, subcategoryId: 2 },
//     { id: 10, name: ' Microwave', categoryId: 1, subcategoryId: 2 },
//     { id: 11, name: ' Fans', categoryId: 1, subcategoryId: 2 },
//     { id: 12, name: ' Vaccum Cleaner', categoryId: 1, subcategoryId: 2 },
//     { id: 13, name: ' Sandwitch maker', categoryId: 1, subcategoryId: 2 },
//     { id: 14, name: ' Cooler', categoryId: 1, subcategoryId: 2 },
 
//     // kitchenware categories
//     // - Storage Container
//     // - Cookware
//     // - Dinnerware
//     // - Pressure Cooker
//     // - Glassware
//     // - GasStove
//     // - Water Bottles
//     // - Lunch boxes
//     // - Cutlery
//     { id: 18, name: ' Storage Container', categoryId: 1, subcategoryId: 4 },
//     { id: 19, name: ' Kitchenware', categoryId: 1, subcategoryId: 3 },
//     { id: 20, name: ' Dinnerware', categoryId: 1, subcategoryId: 3 },
//     { id: 21, name: ' Pressure Cooker', categoryId: 1, subcategoryId: 3 },
//     { id: 22, name: ' Glassware', categoryId: 1, subcategoryId: 3 },
//     { id: 23, name: ' GasStove', categoryId: 1, subcategoryId: 3 },
//     { id: 24, name: ' Water Bottles', categoryId: 1, subcategoryId: 3 },
//     { id: 25, name: ' Lunch boxes', categoryId: 1, subcategoryId: 3 },
//     { id: 26, name: ' Cutlery', categoryId: 1, subcategoryId: 3 },
//     { id: 27, name: ' Laptop Bags', categoryId: 2, subcategoryId: 4 },
//     { id: 28, name: ' Backpacks', categoryId: 2, subcategoryId: 4 },
//     { id: 29, name: ' Suitcase', categoryId: 2, subcategoryId: 4 },
//     { id: 30, name: ' Handbags', categoryId: 2, subcategoryId: 4 },
//     { id: 31, name: ' Duffle Bags', categoryId: 2, subcategoryId: 4 },
//     { id: 32, name: ' Wallets', categoryId: 2, subcategoryId: 4 },
//     { id: 35, name: ' Ganesha', categoryId: 6, subcategoryId: 3 },
//     { id: 36, name: ' Laxmi', categoryId: 6, subcategoryId: 3 },
//     { id: 37, name: ' Saraswati', categoryId: 6, subcategoryId: 3 },
//     { id: 38, name: ' Krishna', categoryId: 6, subcategoryId: 3 },
//     { id: 39, name: ' Shiva', categoryId: 6, subcategoryId: 3 },
// ]

// export const products = [
//     // sweets products
//       { id: 1, name: 'Kaju Katli', description: 'Delicious and mouth-watering kaju katli', imageUrl: process.env.PUBLIC_URL + '/sweets.png', vendorId: 1, price: '19.99', productCategoryId: 6, discount: 10 },
//       { id: 2, name: 'Gulab Jamun', description: 'Soft and spongy gulab jamun', imageUrl: process.env.PUBLIC_URL + '/sweets.png', vendorId: 2, price: '29.99', productCategoryId: 6, discount: 20 },
//       { id: 3, name: 'Rasgulla', description: 'Juicy and sweet rasgulla', imageUrl: process.env.PUBLIC_URL + '/sweets.png', vendorId: 3, price: '39.99', productCategoryId: 6, discount: 30 },
//       { id: 4, name: 'Kaju Roll', description: 'Rich and creamy kaju roll', imageUrl: process.env.PUBLIC_URL + '/sweets.png', vendorId: 4, price: '49.99', productCategoryId: 6, discount: 40 },
//       // cookware products
//       { id: 5, name: 'Bajaj Mixer Grinder', description: 'Versatile and powerful mixer grinder', imageUrl: process.env.PUBLIC_URL + '/kitchen-appliances.png', vendorId: 20, price: '1399.99', productCategoryId: 1, discount: 20 },
//       { id: 6, name: 'Prestige Pressure Cooker', description: 'Durable and efficient pressure cooker', imageUrl: process.env.PUBLIC_URL + '/pressure-cooker.png', vendorId: 19, price: '1299.99', productCategoryId: 4, discount: 10 },
//       { id: 7, name: 'Philips Air Fryer', description: 'Healthy and oil-free cooking with air frying technology', imageUrl: process.env.PUBLIC_URL + '/cookware.jpg', vendorId: 21, price: '1499.99', productCategoryId: 2, discount: 30 },
//       { id: 8, name: 'Hawkins Cookware Set', description: 'Complete set of high-quality cookware', imageUrl: process.env.PUBLIC_URL + '/cookware.jpg', vendorId: 22, price: '1599.99', productCategoryId: 4, discount: 40 },
//       { id: 9, name: 'Pigeon Induction Cooktop', description: 'Efficient and portable induction cooktop', imageUrl: process.env.PUBLIC_URL + '/cookware.jpg', vendorId: 23, price: '1699.99', productCategoryId: 4, discount: 50 },
//       { id: 10, name: 'Wonderchef Non-stick Pan', description: 'Premium non-stick pan for hassle-free cooking', imageUrl: process.env.PUBLIC_URL + '/kitchenware.png', vendorId: 24, price: '1799.99', productCategoryId: 19, discount: 60 },
//       // refrigerator products
//       { id: 11, name: 'LG Side-by-Side', description: 'Elegant and feature-packed side-by-side refrigerator', imageUrl: process.env.PUBLIC_URL + '/ref.png', vendorId: 0, price: '1999.99', productCategoryId: 8, discount: 20 },
//       { id: 12, name: 'Whirlpool Single Door', description: 'Compact and efficient single door refrigerator', imageUrl: process.env.PUBLIC_URL + '/ref.png', vendorId: 27, price: '2099.99', productCategoryId: 0, discount: 30 },
//       { id: 13, name: 'Haier Mini Fridge', description: 'Portable and space-saving mini refrigerator', imageUrl: process.env.PUBLIC_URL + '/ref.png', vendorId: 28, price: '2199.99', productCategoryId: 0, discount: 40 },
//       { id: 14, name: 'Godrej Frost Free', description: 'Frost-free refrigerator with advanced cooling technology', imageUrl: process.env.PUBLIC_URL + '/ref.png', vendorId: 29, price: '2299.99', productCategoryId: 0, discount: 50 },
//       { id: 15, name: 'Panasonic Convertible', description: 'Convertible refrigerator for flexible storage options', imageUrl: process.env.PUBLIC_URL + '/ref.png', vendorId: 30, price: '2399.99', productCategoryId: 0, discount: 60 },
//       // bags products
//       { id: 16, name: 'Skybags Backpack', description: 'Stylish and durable backpack for everyday use', imageUrl: process.env.PUBLIC_URL + '/bags.jpg', vendorId: 32, price: '2499.99', productCategoryId: 28, discount: 10 },
//       { id: 17, name: 'American Tourister Trolley', description: 'Spacious and sturdy trolley bag for travel', imageUrl: process.env.PUBLIC_URL + '/trolly-bag.png', vendorId: 31, price: '2599.99', productCategoryId: 29, discount: 20 },
//       { id: 18, name: 'VIP Duffle Bag', description: 'Versatile and roomy duffle bag for gym or travel', imageUrl: process.env.PUBLIC_URL + '/trolly-bag.png', vendorId: 33, price: '2699.99', productCategoryId: 29, discount: 30 },
//       { id: 19, name: 'Wildcraft Laptop Bag', description: 'Protective and stylish laptop bag', imageUrl: process.env.PUBLIC_URL + '/bags.jpg', vendorId: 34, price: '2799.99', productCategoryId: 27, discount: 40 },
//       { id: 20, name: 'Puma Gym Bag', description: 'Durable and sporty gym bag', imageUrl: process.env.PUBLIC_URL + '/bags.jpg', vendorId: 35, price: '2899.99', productCategoryId: 31, discount: 50 },
//       { id: 21, name: 'Adidas Sling Bag', description: 'Compact and trendy sling bag', imageUrl: process.env.PUBLIC_URL + '/bags.jpg', vendorId: 36, price: '2999.99', productCategoryId: 30, discount: 60 },
  
//       // silver coin products
//       { id: 22, name: 'Shagun 10g', description: 'Pure silver coin weighing 10 grams', imageUrl: process.env.PUBLIC_URL + '/silver1.jpg', vendorId: 37, price: '3099.99', productCategoryId: 0, discount: 10 },
//       { id: 23, name: 'MMTC 20g', description: 'Pure silver coin weighing 20 grams', imageUrl: process.env.PUBLIC_URL + '/silver2.jpg', vendorId: 38, price: '3199.99', productCategoryId: 0, discount: 20 },
      
//       // puja items products
//       { id: 24, name: 'Shagun Diya', description: 'Traditional diya for puja rituals', imageUrl: process.env.PUBLIC_URL + '/puja1.jpg', vendorId: 39, price: '3299.99', productCategoryId: 0, discount: 30 },
//       { id: 25, name: 'MMTC Thali', description: 'Decorative thali for puja ceremonies', imageUrl: process.env.PUBLIC_URL + '/puja2.jpg', vendorId: 40, price: '3399.99', productCategoryId: 0, discount: 40 },
  
//       // puja items products
//       { id: 26, name: 'Shagun Diya', description: 'Traditional diya for puja rituals', imageUrl: process.env.PUBLIC_URL + '/puja1.jpg', vendorId: 41, price: '3299.99', productCategoryId: 0, discount: 50 },
//       { id: 27, name: 'MMTC Thali', description: 'Decorative thali for puja ceremonies', imageUrl: process.env.PUBLIC_URL + '/puja2.jpg', vendorId: 42, price: '3399.99', productCategoryId: 0, discount: 60 },
//       // serverware products
//       { id: 28, name: 'Corelle Dinner Set', description: 'Elegant and durable dinnerware set', imageUrl: process.env.PUBLIC_URL + '/serveware.png', vendorId: 49, price: '3499.99', productCategoryId: 0, discount: 10 },
//       { id: 29, name: 'Borosil Glass Set', description: 'Stylish and heat-resistant glassware set', imageUrl: process.env.PUBLIC_URL + '/serveware.png', vendorId: 50, price: '3599.99', productCategoryId: 0, discount: 20 },
//       { id: 30, name: 'LaOpala Tea Set', description: 'Beautiful and delicate tea set', imageUrl: process.env.PUBLIC_URL + '/serveware.png', vendorId: 51, price: '3699.99', productCategoryId: 0, discount: 30 },
//       { id: 31, name: 'Cello Water Bottle', description: 'Leak-proof and durable water bottle', imageUrl: process.env.PUBLIC_URL + '/serveware.png', vendorId: 52, price: '3799.99', productCategoryId: 0, discount: 40 },
//       { id: 32, name: 'Treo Lunch Box', description: 'Compact and convenient lunch box', imageUrl: process.env.PUBLIC_URL + '/serveware.png', vendorId: 53, price: '3899.99', productCategoryId: 0, discount: 50 },
//       { id: 33, name: 'Larah Cutlery Set', description: 'Elegant and high-quality cutlery set', imageUrl: process.env.PUBLIC_URL + 'serveware.png', vendorId: 54, price: '3999.99', productCategoryId: 0, discount: 60 },
  
//       //storage container products
//       { id: 34, name: 'Tupperware Set', description: 'Durable and airtight storage container set', imageUrl: process.env.PUBLIC_URL + '/storage.png', vendorId: 43, price: '4099.99', productCategoryId: 18, discount: 10 },
//       { id: 35, name: 'Cello Container', description: 'Versatile and stackable food storage container', imageUrl: process.env.PUBLIC_URL + '/storage.png', vendorId: 44, price: '4199.99', productCategoryId: 0, discount: 20 },
//       { id: 36, name: 'Signoraware Box', description: 'Microwave-safe and leak-proof lunch box', imageUrl: process.env.PUBLIC_URL + '/storage.png', vendorId: 45, price: '4299.99', productCategoryId: 0, discount: 30 },
//       { id: 37, name: 'Princeware Jar', description: 'Durable and airtight storage jar', imageUrl: process.env.PUBLIC_URL + '/storage.png', vendorId: 46, price: '4399.99', productCategoryId: 0, discount: 40 },
//       { id: 38, name: 'All Time Basket', description: 'Multipurpose and sturdy storage basket', imageUrl: process.env.PUBLIC_URL + '/storage.png', vendorId: 47, price: '4499.99', productCategoryId: 0, discount: 50 },
//       { id: 39, name: 'Solimo Container', description: 'Transparent and stackable food container', imageUrl: process.env.PUBLIC_URL + '/storage.png', vendorId: 48, price: '4599.99', productCategoryId: 0, discount: 60 },
//       // automobile accessories products
//       { id: 40, name: 'Bosch Car Battery', description: 'High-performance and long-lasting car battery', imageUrl: process.env.PUBLIC_URL + '/automobile.png', vendorId: 61, price: '4699.99', productCategoryId: 0, discount: 10 },
//       { id: 41, name: '3M Car Care Kit', description: 'Complete car care kit for cleaning and maintenance', imageUrl: process.env.PUBLIC_URL + '/automobile.png', vendorId: 62, price: '4799.99', productCategoryId: 0, discount: 20 },
//       { id: 42, name: 'Vega Helmet', description: 'Stylish and safe helmet for bike riders', imageUrl: process.env.PUBLIC_URL + '/automobile.png', vendorId: 63, price: '4899.99', productCategoryId: 0, discount: 30 },
//       { id: 43, name: 'Studds Riding Jacket', description: 'Protective and comfortable riding jacket', imageUrl: process.env.PUBLIC_URL + '/automobile.png', vendorId: 64, price: '4999.99', productCategoryId: 0, discount: 40 },
//       { id: 44, name: 'Steelbird Helmet', description: 'Durable and lightweight helmet for bikers', imageUrl: process.env.PUBLIC_URL + '/automobile.png', vendorId: 65, price: '5099.99', productCategoryId: 0, discount: 50 },
//       { id: 45, name: 'MRF Tyre', description: 'Reliable and high-performance tyre for vehicles', imageUrl: process.env.PUBLIC_URL + '/automobile.png', vendorId: 66, price: '5199.99', productCategoryId: 0, discount: 60 },
  
//       // personal care products
//       { id: 46, name: 'Philips Trimmer', description: 'Versatile and efficient beard trimmer', imageUrl: process.env.PUBLIC_URL + '/personal-care.png', vendorId: 55, price: '5299.99', productCategoryId: 0, discount: 10 },
//       { id: 47, name: 'Braun Shaver', description: 'Advanced and precise electric shaver', imageUrl: process.env.PUBLIC_URL + '/personal-care.png', vendorId: 56, price: '5399.99', productCategoryId: 0, discount: 20 },
//       { id: 48, name: 'Panasonic Hair Dryer', description: 'Fast and gentle hair dryer for styling', imageUrl: process.env.PUBLIC_URL + '/personal-care.png', vendorId: 57, price: '5499.99', productCategoryId: 0, discount: 30 },
//       { id: 49, name: 'Vega Hair Straightener', description: 'Sleek and professional hair straightener', imageUrl: process.env.PUBLIC_URL + '/personal-care.png', vendorId: 58, price: '5599.99', productCategoryId: 0, discount: 40 },
//       { id: 50, name: 'Syska Trimmer', description: 'Stylish and efficient beard trimmer', imageUrl: process.env.PUBLIC_URL + '/personal-care.png', vendorId: 59, price: '5699.99', productCategoryId: 0, discount: 50 },
//       // health and wellness products
//       { id: 51, name: 'Dabur Chyawanprash', description: 'Immunity-boosting and rejuvenating health tonic', imageUrl: process.env.PUBLIC_URL + '/health.png', vendorId: 67, price: '5899.99', productCategoryId: 0, discount: 10 },
//       { id: 52, name: 'Patanjali Honey', description: 'Pure and natural honey for good health', imageUrl: process.env.PUBLIC_URL + '/health.png', vendorId: 68, price: '5999.99', productCategoryId: 0, discount: 20 },
//       { id: 53, name: 'Himalaya Neem Face Wash', description: 'Gentle and effective face wash for clear skin', imageUrl: process.env.PUBLIC_URL + '/health.png', vendorId: 69, price: '6099.99', productCategoryId: 0, discount: 30 },
//       { id: 54, name: 'Amway Nutrilite Protein Powder', description: 'Rich and nutritious protein supplement', imageUrl: process.env.PUBLIC_URL + '/health.png', vendorId: 70, price: '6199.99', productCategoryId: 0, discount: 40 },
//       { id: 55, name: 'Baidyanath Chyawanprash', description: 'Ayurvedic and immunity-boosting health tonic', imageUrl: process.env.PUBLIC_URL + '/health.png', vendorId: 71, price: '6299.99', productCategoryId: 0, discount: 50 },
//       { id: 56, name: 'Zandu Balm', description: 'Fast and effective pain relief balm', imageUrl: process.env.PUBLIC_URL + '/health.png', vendorId: 72, price: '6399.99', productCategoryId: 0, discount: 60 },
//       // leather accessories products
//       { id: 57, name: 'Hidesign Leather Bag', description: 'Stylish and premium leather handbag', imageUrl: process.env.PUBLIC_URL + '/leather.png', vendorId: 73, price: '6499.99', productCategoryId: 0, discount: 10 },
//       { id: 58, name: 'Da Milano Wallet', description: 'Luxurious and sophisticated leather wallet', imageUrl: process.env.PUBLIC_URL + '/leather.png', vendorId: 74, price: '6599.99', productCategoryId: 0, discount: 20 },
//       { id: 59, name: 'Baggit Sling Bag', description: 'Trendy and versatile leather sling bag', imageUrl: process.env.PUBLIC_URL + '/leather.png', vendorId: 75, price: '6699.99', productCategoryId: 0, discount: 30 },
//       { id: 60, name: 'Caprese Tote Bag', description: 'Chic and fashionable leather tote bag', imageUrl: process.env.PUBLIC_URL + '/leather.png', vendorId: 76, price: '6799.99', productCategoryId: 0, discount: 40 },
//       { id: 61, name: 'Lavie Clutch', description: 'Elegant and stylish leather clutch', imageUrl: process.env.PUBLIC_URL + '/leather.png', vendorId: 77, price: '6899.99', productCategoryId: 0, discount: 50 },
//       { id: 62, name: 'Wildcraft Backpack', description: 'Durable and rugged leather backpack', imageUrl: process.env.PUBLIC_URL + '/leather.png', vendorId: 78, price: '6999.99', productCategoryId: 0, discount: 60 },
//       // audio products
//       { id: 63, name: 'Sony Headphones', description: 'High-quality and immersive audio experience', imageUrl: process.env.PUBLIC_URL + '/audio.png', vendorId: 79, price: '7099.99', productCategoryId: 0, discount: 10 },
//       { id: 64, name: 'JBL Bluetooth Speaker', description: 'Portable and powerful Bluetooth speaker', imageUrl: process.env.PUBLIC_URL + '/audio.png', vendorId: 80, price: '7199.99', productCategoryId: 0, discount: 20 },
//       { id: 65, name: 'Boat Earphones', description: 'Sleek and stylish earphones with deep bass', imageUrl: process.env.PUBLIC_URL + '/audio.png', vendorId: 81, price: '7299.99', productCategoryId: 0, discount: 30 },
//       { id: 66, name: 'Bose Soundbar', description: 'Premium and cinematic soundbar for home entertainment', imageUrl: process.env.PUBLIC_URL + '/audio.png', vendorId: 82, price: '7399.99', productCategoryId: 0, discount: 40 },
//       { id: 67, name: 'Philips Home Theatre', description: 'Immersive and powerful home theatre system', imageUrl: process.env.PUBLIC_URL + '/audio.png', vendorId: 83, price: '7499.99', productCategoryId: 0, discount: 50 },
//       { id: 68, name: 'Sennheiser Wireless Headphones', description: 'Wireless and noise-cancelling headphones', imageUrl: process.env.PUBLIC_URL + '/audio.png', vendorId: 84, price: '7599.99', productCategoryId: 0, discount: 60 },
//       // video display products
//       { id: 69, name: 'Sony LED TV', description: 'High-quality and immersive television experience', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 85, price: '7699.99', productCategoryId: 0, discount: 10 },
//       { id: 70, name: 'LG OLED TV', description: 'Stunning picture quality with vibrant colors', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 86, price: '7799.99', productCategoryId: 0, discount: 20 },
//       { id: 71, name: 'Samsung QLED TV', description: 'Crystal clear display with true-to-life colors', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 87, price: '7899.99', productCategoryId: 0, discount: 30 },
//       { id: 72, name: 'OnePlus TV', description: 'Smart TV with seamless connectivity and premium features', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 88, price: '7999.99', productCategoryId: 0, discount: 40 },
//       { id: 73, name: 'Mi TV', description: 'Affordable and feature-packed television', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 89, price: '8099.99', productCategoryId: 0, discount: 50 },
//       { id: 74, name: 'Realme TV', description: 'Sleek and stylish smart TV', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 90, price: '8199.99', productCategoryId: 0, discount: 60 },
//       // computer accessories products
//       { id: 75, name: 'Dell Laptop', description: 'Powerful and versatile laptop for work and play', imageUrl: process.env.PUBLIC_URL + '/computers.png', vendorId: 91, price: '8299.99', productCategoryId: 0, discount: 10 },
//       { id: 76, name: 'HP Desktop', description: 'High-performance and reliable desktop computer', imageUrl: process.env.PUBLIC_URL + '/computers.png', vendorId: 92, price: '8399.99', productCategoryId: 0, discount: 20 },
//       { id: 77, name: 'Apple MacBook', description: 'Sleek and premium laptop for professionals', imageUrl: process.env.PUBLIC_URL + '/computers.png', vendorId: 93, price: '8499.99', productCategoryId: 0, discount: 30 },
//       { id: 78, name: 'Lenovo Tablet', description: 'Compact and versatile tablet for entertainment', imageUrl: process.env.PUBLIC_URL + '/computers.png', vendorId: 94, price: '8599.99', productCategoryId: 0, discount: 40 },
//       { id: 79, name: 'Asus Monitor', description: 'High-resolution and immersive computer monitor', imageUrl: process.env.PUBLIC_URL + '/computers.png', vendorId: 95, price: '8699.99', productCategoryId: 0, discount: 50 },
//       { id: 80, name: 'Acer Keyboard', description: 'Ergonomic and responsive computer keyboard', imageUrl: process.env.PUBLIC_URL + '/computers.png', vendorId: 96, price: '8799.99', productCategoryId: 0, discount: 60 },
//       // home decor products
//       { id: 81, name: 'FabIndia Curtains', description: 'Elegant and decorative curtains for home', imageUrl: process.env.PUBLIC_URL + '/home-decor.png', vendorId: 127, price: '8999.99', productCategoryId: 0, discount: 20 },
//       { id: 82, name: 'Home Centre Sofa', description: 'Comfortable and stylish sofa for living room', imageUrl: process.env.PUBLIC_URL + '/home-decor.png', vendorId: 132, price: '8899.99', productCategoryId: 0, discount: 10 },
//       { id: 83, name: 'Urban Ladder Dining Table', description: 'Stylish and functional dining table for home', imageUrl: process.env.PUBLIC_URL + '/home-decor.png', vendorId: 131, price: '9199.99', productCategoryId: 0, discount: 40 },
     
//       // furniture products
//       { id: 84, name: 'Godrej Almirah', description: 'Durable and spacious almirah for storage', imageUrl: process.env.PUBLIC_URL + '/furniture.png', vendorId: 123, price: '9499.99', productCategoryId: 0, discount: 10 },
//       { id: 85, name: 'Nilkamal Chair', description: 'Comfortable and ergonomic chair for office or home', imageUrl: process.env.PUBLIC_URL + '/furniture.png', vendorId: 124, price: '9599.99', productCategoryId: 0, discount: 20 },
//       { id: 86, name: 'Durian Sofa Set', description: 'Luxurious and stylish sofa set for living room', imageUrl: process.env.PUBLIC_URL + '/furniture.png', vendorId: 125, price: '9699.99', productCategoryId: 0, discount: 30 },
//       // fregnerance & essential oils products
//       { id: 87, name: 'Good Vibes Essential Oil', description: 'Pure and natural essential oil for aromatherapy', imageUrl: process.env.PUBLIC_URL + '/perfumes.png', vendorId: 133, price: '10699.99', productCategoryId: 0, discount: 10 },
//       { id: 88, name: 'Kama Ayurveda Perfume', description: 'Luxurious and long-lasting perfume', imageUrl: process.env.PUBLIC_URL + '/perfumes.png', vendorId: 134, price: '10799.99', productCategoryId: 0, discount: 20 },
//       { id: 89, name: 'Forest Essentials Attar', description: 'Exotic and traditional attar for fragrance', imageUrl: process.env.PUBLIC_URL + '/perfumes.png', vendorId: 135, price: '10899.99', productCategoryId: 0, discount: 30 },
//       { id: 90, name: 'Soulflower Aroma Oil', description: 'Calming and soothing aroma oil for relaxation', imageUrl: process.env.PUBLIC_URL + '/perfumes.png', vendorId: 136, price: '10999.99', productCategoryId: 0, discount: 40 },
//       { id: 91, name: 'Juicy Chemistry Perfume', description: 'Organic and natural perfume for everyday use', imageUrl: process.env.PUBLIC_URL + '/perfumes.png', vendorId: 137, price: '11099.99', productCategoryId: 0, discount: 50 },  
//       // smart watches products
//       { id: 92, name: 'Apple Watch', description: 'Advanced and feature-packed smartwatch for fitness', imageUrl: process.env.PUBLIC_URL + '/smart-watch.png', vendorId: 97, price: '10099.99', productCategoryId: 0, discount: 10 },
//       { id: 93, name: 'Samsung Galaxy Watch', description: 'Affordable and versatile fitness band', imageUrl: process.env.PUBLIC_URL + '/smart-watch.png', vendorId: 98, price: '10199.99', productCategoryId: 0, discount: 20 },
//       // home appliances products
//       { id: 94, name: 'Philips Iron', description: 'Powerful and efficient steam iron for clothes', imageUrl: process.env.PUBLIC_URL + '/iron.png', vendorId: 25, price: '10699.99', productCategoryId: 7, discount: 10 },
//       { id: 95, name: 'Bajaj Mixer Grinder', description: 'Versatile and powerful mixer grinder for kitchen', imageUrl: process.env.PUBLIC_URL + '/home-appliances.png', vendorId: 8, price: '10799.99', productCategoryId: 0, discount: 20 },
//       { id: 96, name: 'Havells Water Purifier', description: 'Advanced and efficient water purifier for home', imageUrl: process.env.PUBLIC_URL + '/home-appliances.png', vendorId: 9, price: '10899.99', productCategoryId: 9, discount: 30 },
//       { id: 97, name: 'LG Microwave Oven', description: 'Smart and feature-packed microwave oven', imageUrl: process.env.PUBLIC_URL + '/home-appliances.png', vendorId: 10, price: '10999.99', productCategoryId: 10, discount: 40 },
//       { id: 98, name: 'Samsung Refrigerator', description: 'Energy-efficient and spacious refrigerator', imageUrl: process.env.PUBLIC_URL + '/refrigerator.png', vendorId: 11, price: '11099.99', productCategoryId: 8, discount: 50 },
//       { id: 99, name: 'Whirlpool Washing Machine', description: 'High-performance and durable washing machine', imageUrl: process.env.PUBLIC_URL + '/home-appliances.png', vendorId: 12, price: '11199.99', productCategoryId: 12, discount: 60 },
//       // smart robots products
//       { id: 100, name: 'iRobot Roomba', description: 'Advanced and efficient robot vacuum cleaner', imageUrl: process.env.PUBLIC_URL + '/smart-robots.png', vendorId: 103, price: '11299.99', productCategoryId: 0, discount: 10 },
//       { id: 101, name: 'Milagrow Seagull', description: 'Smart and versatile floor cleaning robot', imageUrl: process.env.PUBLIC_URL + '/smart-robots.png', vendorId: 104, price: '11399.99', productCategoryId: 0, discount: 20 },
//       // bed linen products
//       { id: 102, name: 'Bombay Dyeing Bedsheet', description: 'Soft and comfortable bedsheet for a good night\'s sleep', imageUrl: process.env.PUBLIC_URL + '/bed-linen.png', vendorId: 109, price: '11499.99', productCategoryId: 0, discount: 30 },
//       { id: 103, name: 'Spaces Pillow Cover', description: 'Stylish and durable pillow cover for bedroom', imageUrl: process.env.PUBLIC_URL + '/bed-linen.png', vendorId: 110, price: '11599.99', productCategoryId: 0, discount: 40 },
//       { id: 104, name: 'Portico Blanket', description: 'Warm and cozy blanket for cold nights', imageUrl: process.env.PUBLIC_URL + '/bed-linen.png', vendorId: 111, price: '11699.99', productCategoryId: 0, discount: 50 },
//       { id: 105, name: 'Raymond Bath Towel', description: 'Absorbent and soft bath towel for everyday use', imageUrl: process.env.PUBLIC_URL + '/bed-linen.png', vendorId: 112, price: '11799.99', productCategoryId: 0, discount: 60 },
//       // blenket products
//       { id: 106, name: 'Bombay Dyeing Blanket', description: 'Soft and comfortable blanket for a good night\'s sleep', imageUrl: process.env.PUBLIC_URL + '/blankets.png', vendorId: 115, price: '11899.99', productCategoryId: 0, discount: 10 },
//       { id: 107, name: 'Spaces Blanket', description: 'Stylish and durable blanket for bedroom', imageUrl: process.env.PUBLIC_URL + '/blankets.png', vendorId: 116, price: '11999.99', productCategoryId: 0, discount: 20 },
//       { id: 108, name: 'Portico Blanket', description: 'Warm and cozy blanket for cold nights', imageUrl: process.env.PUBLIC_URL + '/blankets.png', vendorId: 117, price: '12099.99', productCategoryId: 0, discount: 30 },
//       { id: 109, name: 'Raymond Blanket', description: 'Absorbent and soft blanket for everyday use', imageUrl: process.env.PUBLIC_URL + '/blankets.png', vendorId: 118, price: '12199.99', productCategoryId: 0, discount: 40 },
//       // gold coin products
//       { id: 110, name: 'Shagun 10g', description: 'Pure gold coin weighing 10 grams', imageUrl: process.env.PUBLIC_URL + '/mmtc-gold.png', vendorId: 37, price: '3299.99', productCategoryId: 0, discount: 30 },
//       { id: 111, name: 'MMTC 20g', description: 'Pure gold coin weighing 20 grams', imageUrl: process.env.PUBLIC_URL + '/mmtc-gold.png', vendorId: 38, price: '3399.99', productCategoryId: 0, discount: 40 },
//       // utensils products
//       { id: 112, name: 'Prestige Cookware Set', description: 'Durable and versatile cookware set for kitchen', imageUrl: process.env.PUBLIC_URL + '/utensils.png', vendorId: 139, price: '12299.99', productCategoryId: 0, discount: 50 },
//       { id: 113, name: 'Hawkins Pressure Cooker', description: 'Sturdy and efficient pressure cooker for cooking', imageUrl: process.env.PUBLIC_URL + '/utensils.png', vendorId: 140, price: '12399.99', productCategoryId: 0, discount: 60 },
//       { id: 114, name: 'Pigeon Pressure Cooker', description: 'Sturdy and efficient pressure cooker for cooking', imageUrl: process.env.PUBLIC_URL + '/utensils.png', vendorId: 141, price: '12399.99', productCategoryId: 0, discount: 60 },
//       { id: 115, name: 'Vinod Cookware Set', description: 'Durable and versatile cookware set for kitchen', imageUrl: process.env.PUBLIC_URL + '/utensils.png', vendorId: 142, price: '12499.99', productCategoryId: 0, discount: 10 },
//       { id: 116, name: 'Vinod Pressure Cooker', description: 'Sturdy and efficient pressure cooker for cooking', imageUrl: process.env.PUBLIC_URL + '/utensils.png', vendorId: 142, price: '12599.99', productCategoryId: 0, discount: 20 },
//       // idols products
//       { id: 117, name: 'Ganesha Idol', description: 'Beautiful and auspicious idol of Lord Ganesha', imageUrl: process.env.PUBLIC_URL + '/idols.png', vendorId: 0, price: '12699.99', productCategoryId: 35, discount: 30 },
//       { id: 118, name: 'Lakshmi Idol', description: 'Elegant and divine idol of Goddess Lakshmi', imageUrl: process.env.PUBLIC_URL + '/idols.png', vendorId: 0, price: '12799.99', productCategoryId: 36, discount: 40 },
//       { id: 119, name: 'Krishna Idol', description: 'Traditional and beautiful idol of Lord Krishna', imageUrl: process.env.PUBLIC_URL + '/idols.png', vendorId: 0, price: '12899.99', productCategoryId: 38, discount: 50 },
//       { id: 120, name: 'Saraswati Idol', description: 'Artistic and divine idol of Goddess Saraswati', imageUrl: process.env.PUBLIC_URL + '/idols.png', vendorId: 0, price: '12999.99', productCategoryId: 37, discount: 60 },
//       { id: 121, name: 'Shiva Idol', description: 'Majestic and powerful idol of Lord Shiva', imageUrl: process.env.PUBLIC_URL + '/idols.png', vendorId: 0, price: '13099.99', productCategoryId: 39, discount: 10 },
//       // video display products
//         { id: 122, name: 'Sony LED TV', description: 'High-quality and immersive television experience', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 85, price: '7699.99', productCategoryId: 0, discount: 10 },
//         { id: 123, name: 'LG OLED TV', description: 'Stunning picture quality with vibrant colors', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 86, price: '7799.99', productCategoryId: 0, discount: 20 },
//         { id: 124, name: 'Samsung QLED TV', description: 'Crystal clear display with true-to-life colors', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 87, price: '7899.99', productCategoryId: 0, discount: 30 },
//         { id: 125, name: 'OnePlus TV', description: 'Smart TV with seamless connectivity and premium features', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 88, price: '7999.99', productCategoryId: 0, discount: 40 },
//         { id: 126, name: 'Mi TV', description: 'Affordable and feature-packed television', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 89, price: '8099.99', productCategoryId: 0, discount: 50 },
//         { id: 127, name: 'Realme TV', description: 'Sleek and stylish smart TV', imageUrl: process.env.PUBLIC_URL + '/video-display.png', vendorId: 90, price: '8199.99', productCategoryId: 0, discount: 60 },
//         // electric kettle products
//         { id: 128, name: 'Prestige Electric Kettle', description: 'Efficient and stylish electric kettle for kitchen', imageUrl: process.env.PUBLIC_URL + '/electric-kettle.png', vendorId: 143, price: '13199.99', productCategoryId: 0, discount: 10 },
//         { id: 129, name: 'Philips Electric Kettle', description: 'Fast and convenient electric kettle for home', imageUrl: process.env.PUBLIC_URL + '/electric-kettle.png', vendorId: 144, price: '13299.99', productCategoryId: 0, discount: 20 },
//         { id: 130, name: 'Bajaj Electric Kettle', description: 'Durable and efficient electric kettle for kitchen', imageUrl: process.env.PUBLIC_URL + '/electric-kettle.png', vendorId: 145, price: '13399.99', productCategoryId: 0, discount: 30 },
//         { id: 131, name: 'Morphy Richards Electric Kettle', description: 'Stylish and versatile electric kettle for home', imageUrl: process.env.PUBLIC_URL + '/electric-kettle.png', vendorId: 146, price: '13499.99', productCategoryId: 0, discount: 40 },
//   ];
  