import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    wishlist: [],
    status: 'idle',
    error: null,
};

export const addToWishlist = createAsyncThunk('wishlist/addToWishlist', async (product) => {
    // const response = await axios.post('/api/wishlist', product);
    return product;
});

export const removeFromWishlist = createAsyncThunk('wishlist/removeFromWishlist', async (productId) => {
    // const response = await axios.delete('/api/wishlist', productId);
    return productId;
});


export const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(addToWishlist.fulfilled, (state, action) => {
                state.wishlist = [...state.wishlist, action.payload];
            })
            .addCase(removeFromWishlist.fulfilled, (state, action) => {
                state.wishlist = state.wishlist.filter(product => product.id !== action.payload);
            });
    },
});

export default wishlistSlice.reducer;