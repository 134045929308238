import React from 'react';
import { Typography } from '@mui/material';
import { List, ListItemButton, Paper } from '@mui/material';

const Brands = ({ filteredVendors, selectedVendor, handleVendorClick }) => {
    return (
        <>
            {filteredVendors.length > 0 && (
                <Paper sx={{ padding: '8px' }}>
                    <Typography pt={1} mb={1} variant='h6'>BRAND</Typography>
                    <List disablePadding>
                        {filteredVendors?.map((vendor) => (
                            <ListItemButton
                                sx={{
                                    backgroundColor: vendor.id === selectedVendor ? '#e9fdf4' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: '#e9fdf4', // Change this to your desired hover color
                                    },
                                }}
                                key={vendor.id}
                                onClick={() => handleVendorClick(vendor.id)}
                            >
                                {vendor.name}
                            </ListItemButton>
                        ))}
                    </List>
                </Paper>
            )}
        </>
    );
};

export default Brands;