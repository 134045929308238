import React from 'react';
import { Container, Paper, Typography, Grid, Box } from '@mui/material';

const About = () => {

  const listItems = [
    "Writing Instruments",
    "Kitchen & Home Appliances",
    "Electronics",
    "Luggage & Backpacks",
    "Automobile & Accessories",
    "Audio Visual Systems",
    "Executive Leather Bags",
    "Health & Wellness Items",
    "Computer & IT Products",
    "Gift Cards",
    "Gold & Silver Coins / Bars",
    "DIY Tools",
    "Apparels and Many More..."
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Banner Image */}
      <Box sx={{ mb: 4 }}>
        <img
          src={process.env.PUBLIC_URL + '/banner.png'}  // Replace with the path to your banner image
          alt="Banner"
          style={{ width: '100%', height: 'auto' }}
        />
      </Box>

      <Paper elevation={0} sx={{ padding: '32px', backgroundColor: 'white' }}>
        <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold', color: 'primary.dark' }}>
          Welcome to OrderMyGift – Your Trusted Gifting Partner!
        </Typography>
        <Typography mt={2} variant="body1" align="justify" sx={{ lineHeight: 1.6 }}>
          <p>OrderMyGift is your one-stop destination for premium gifting solutions, offering exclusive
            Institutional and bulk rates for all your needs. Say goodbye to the hassle of overcrowded shops and
            long queues – with our easy-to-use portal, you can pre-book your gifts from the comfort of your
            home or office desk and get them delivered right to your doorstep. Whether it's for personal
            occasions or corporate events, we provide a wide variety of high-quality gifts tailored to every taste
            and budget. Make your celebrations stress-free with OrderMyGift!</p>

          <p class="quote">"OrderMyGift – So Amazing, You’ll Say ‘Oh My God!’"</p>

          <div class="section">
            <h2>About Us</h2>
            <p>We are an Indian start-up company into enterprise sourcing, driven by industry veterans from
              different industry sectors. We are headquartered in Delhi, India, with branch offices in
              Ghaziabad and Bangalore.</p>
            <p>We offer an entire gamut of sourcing solutions for corporate and institutional clients, helping
              organizations procure the best products at competitive prices, customized to their requirements and
              delivered within stringent timelines.</p>
            <p>For corporate gifting, we have gifts for all occasions: employee engagements, channel partner
              gifting, customer rewards, festival gifting, or any other special occasions. We have more than
              50,000+ products from various brands and quality manufacturers across the world in the following
              categories:</p>
            <Grid container spacing={2}>
              {listItems.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <li>{item}</li>
                </Grid>
              ))}
            </Grid>
            <p>We are associated with renowned brands and global leaders for procurement of respective product
              categories from various geographies all over the world. We import from Italy, Spain, Netherlands,
              Taiwan, Korea, New Zealand, etc.</p>
          </div>
        </Typography>
      </Paper>
    </Container>
  );
};

export default About;