import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Retrieve cart items from local storage
const retrieveCartFromLocalStorage = () => {
    const cartItems = localStorage.getItem('cart');
    return cartItems ? JSON.parse(cartItems) : [];
};


const initialState = {
    cart: retrieveCartFromLocalStorage() ?? [],
};

// Save cart items to local storage
const saveCartToLocalStorage = (cartItems) => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
};

export const fetchCart = createAsyncThunk('cart/fetchCart', async () => {
    const response = await axios.get('/api/cart');
    return response.data;
});

// create cart
export const createCart = createAsyncThunk('cart/createCart', async (product) => {
    // const response = await axios.post('/api/cart', product);
    return product;
});

export const updateCart = createAsyncThunk('cart/updateCart', async (product) => {
    // const response = await axios.put('/api/cart', cart);
    return product;
});

// decrement cart
export const decrementCart = createAsyncThunk('cart/decrementCart', async (product) => {
    // const response = await axios.put('/api/cart/decrement', product);
    return product;
});

export const deleteCart = createAsyncThunk('cart/deleteCart', async () => {
    // const response = await axios.delete('/api/cart');
    return [];
});


export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCart.fulfilled, (state, action) => {
                state.cart = action.payload;
            })
            .addCase(createCart.fulfilled, (state, action) => {
                state.cart = [...state.cart, action.payload];
                saveCartToLocalStorage(state.cart);
            })
            .addCase(updateCart.fulfilled, (state, action) => {
                state.cart = [...state.cart, action.payload];
                saveCartToLocalStorage(state.cart);
            })
            .addCase(decrementCart.fulfilled, (state, action) => {
                state.cart = action.payload;
                saveCartToLocalStorage(state.cart);
            })
            .addCase(deleteCart.fulfilled, (state, action) => {
                state.cart = [];
                saveCartToLocalStorage(state.cart);
            });
    },
});

// Retrieve cart items from local storage when the app starts
cartSlice['preloadedState'] = {
    cart: retrieveCartFromLocalStorage(),
};

export default cartSlice.reducer;