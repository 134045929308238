import React from 'react';
import { Typography, Container, Box, Link, Grid, IconButton } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const StyledLink = ({ children, ...props }) => (
  <Link
    {...props}
    sx={{
      color: 'text.secondary',
      '&:hover': {
        color: 'blue',
      },
    }}
  >
    {children}
  </Link>
);

const Footer = () => {
  const navigate = useNavigate();

  const onLinkClick = (page) => {
    navigate(page);
  }

  const productCategories = [
    "Kitchen Appliances",
    "Home Appliances",
    "Electronics",
    "Automobile & Accessories",
    "Personal Care",
    "Luggage & Backpacks",
    "Home Furnishing ",
    "Home Décor",
  ];

  return (
    <Box mt={4} sx={{ backgroundColor: 'secondary.main', padding: '16px 0' }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" gutterBottom>
              Useful Links
            </Typography>
            <StyledLink variant='body2' href="#" onClick={() => onLinkClick('/terms')}>Terms & Conditions</StyledLink><br />
            <StyledLink variant='body2' href="#" onClick={() => onLinkClick('/privacy')}>Privacy Policy</StyledLink><br />
            <StyledLink variant='body2' href="#" onClick={() => onLinkClick('/shipping')}>Shipping Policy</StyledLink><br />
            <StyledLink variant='body2' href="#" onClick={() => onLinkClick('/cancellation')}>Cancellation Policy</StyledLink><br />
            <StyledLink variant='body2' href="#" onClick={() => onLinkClick('/refund')}>Refund Policy</StyledLink><br />
            <StyledLink variant='body2' href="#" onClick={() => onLinkClick('/about')}>About Us</StyledLink>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" gutterBottom>
              Categories
            </Typography>
            {
              productCategories.map((category, index) => (
                <Typography key={index} variant='body2'>{category}</Typography>
              ))
            }
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant='body2'># 205, Vardhman Bahnhof Plaza</Typography>
            <Typography variant='body2'>Plot No. 6, Pocket - 7</Typography>
            <Typography variant='body2'>Sector -12, Dwarka</Typography>
            <Typography variant='body2'>New Delhi - 110078, INDIA</Typography>
            <Typography variant='body2'>Email: Support@procurehelpdesk.com, Info@procurehelpdesk.com</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography align='center' variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
              <IconButton href="https://facebook.com" target="_blank" rel="noopener">
                <Facebook />
              </IconButton>
              <IconButton href="https://twitter.com" target="_blank" rel="noopener">
                <Twitter />
              </IconButton>
              <IconButton href="https://instagram.com" target="_blank" rel="noopener">
                <Instagram />
              </IconButton>
              <IconButton href="https://linkedin.com" target="_blank" rel="noopener">
                <LinkedIn />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          © {new Date().getFullYear()} OrderMyGift Org. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;